import { getLayouts } from './Kbdata'

const LINE_COLOR = '#f06'
const LINE_WIDTH = 8
const LINE_CAP = 'round'
const LINE_LENGTH = 25
const RECT_MARGIN = 8
let warning = null

function checkIfOutOfRange(
  keys, 
  type, 
  KEY_START_X, 
  KEY_START_Y,
  KEY_SCALE
) {
  let numx = null
  let numy = null
  if (type === 1) {
    numx = 4
    numy = 4
  } else if (type === 2) {
    numx = 5
    numy = 3
  } else if (type === 7) {
    numx = 5
    numy = 5
  } else if (type === 8) {
    numx = 5
    numy = 5
  }
  const RECT_WIDTH = (19 * numx)
  const RECT_HEIGHT = (19 * numy)
  const layouts = getLayouts(
    keys, 
    KEY_START_X,
    KEY_START_Y,
    KEY_SCALE
  )
  if (layouts.board.key.w > RECT_WIDTH || layouts.board.key.h > RECT_HEIGHT) {
    return true
  }
  return false
}

function showWarning(
  svg_container, 
  type,  
  KEY_START_X, 
  KEY_START_Y,
  KEY_SCALE
) {
  if (warning) {
    return
  }
  let numx = null
  let numy = null
  let messx = null
  if (type === 1) {
    numx = 4
    numy = 4
    messx = 0
  } else if (type === 2) {
    numx = 5
    numy = 3
    messx = 26
  } else if (type === 7) {
    numx = 5
    numy = 5
    messx = 26
  } else if (type === 8) {
    numx = 5
    numy = 5
    messx = 0
  }
  const RECT_WIDTH = (19 * numx) * KEY_SCALE
  const RECT_HEIGHT = (19 * numy) * KEY_SCALE
  warning = svg_container.nested()
  warning.on('mousedown', mousedown)
  const LINE_OFFSET_X = KEY_START_X - RECT_MARGIN + LINE_WIDTH / 2
  const LINE_OFFSET_Y = KEY_START_Y - RECT_MARGIN + LINE_WIDTH / 2
  warning.rect(3000, 3000).attr({
    fill: LINE_COLOR,
    'fill-opacity': 0.1
  }) // for click event
  warning.rect(RECT_WIDTH, RECT_HEIGHT).radius(4).attr({
    fill: LINE_COLOR,
    'fill-opacity': 0.2,
    x: LINE_OFFSET_X,
    y: LINE_OFFSET_Y
  })
  warning.text("Any keys must be in this area.").font({
    fill: LINE_COLOR, 
    family: 'Helvetica', 
    size: 17,
    x: LINE_OFFSET_X + messx,
    y: LINE_OFFSET_Y + RECT_HEIGHT / 2 + 4
  })
  const lines = warning.nested()
  lines.line(
    LINE_OFFSET_X, 
    LINE_OFFSET_Y, 
    LINE_OFFSET_X, 
    LINE_LENGTH + LINE_OFFSET_Y
  ).stroke({ 
    width: LINE_WIDTH,
    color: LINE_COLOR, 
    linecap: LINE_CAP 
  })
  lines.line(
    LINE_OFFSET_X, 
    LINE_OFFSET_Y, 
    LINE_LENGTH + LINE_OFFSET_X, 
    LINE_OFFSET_Y
  ).stroke({ 
    width: LINE_WIDTH,
    color: LINE_COLOR, 
    linecap: LINE_CAP 
  })
  lines.line(
    LINE_OFFSET_X, 
    RECT_HEIGHT + LINE_OFFSET_Y, 
    LINE_OFFSET_X, 
    RECT_HEIGHT + LINE_OFFSET_Y - LINE_LENGTH
  ).stroke({ 
    width: LINE_WIDTH,
    color: LINE_COLOR, 
    linecap: LINE_CAP 
  })
  lines.line(
    LINE_OFFSET_X, 
    RECT_HEIGHT + LINE_OFFSET_Y, 
    LINE_LENGTH + LINE_OFFSET_X, 
    RECT_HEIGHT + LINE_OFFSET_Y
  ).stroke({ 
    width: LINE_WIDTH,
    color: LINE_COLOR, 
    linecap: LINE_CAP 
  })
  lines.line(
    RECT_WIDTH + LINE_OFFSET_X, 
    LINE_OFFSET_Y, 
    RECT_WIDTH + LINE_OFFSET_X - LINE_LENGTH, 
    LINE_OFFSET_Y
  ).stroke({ 
    width: LINE_WIDTH,
    color: LINE_COLOR, 
    linecap: LINE_CAP 
  })
  lines.line(
    RECT_WIDTH + LINE_OFFSET_X, 
    LINE_OFFSET_Y, 
    RECT_WIDTH + LINE_OFFSET_X, 
    LINE_LENGTH + LINE_OFFSET_Y
  ).stroke({ 
    width: LINE_WIDTH,
    color: LINE_COLOR, 
    linecap: LINE_CAP 
  })
  lines.line(
    RECT_WIDTH + LINE_OFFSET_X, 
    RECT_HEIGHT + LINE_OFFSET_Y, 
    RECT_WIDTH + LINE_OFFSET_X, 
    RECT_HEIGHT + LINE_OFFSET_Y - LINE_LENGTH
  ).stroke({ 
    width: LINE_WIDTH,
    color: LINE_COLOR, 
    linecap: LINE_CAP 
  })
  lines.line(
    RECT_WIDTH + LINE_OFFSET_X, 
    RECT_HEIGHT + LINE_OFFSET_Y, 
    RECT_WIDTH + LINE_OFFSET_X - LINE_LENGTH, 
    RECT_HEIGHT + LINE_OFFSET_Y
  ).stroke({ 
    width: LINE_WIDTH,
    color: LINE_COLOR, 
    linecap: LINE_CAP 
  })
}

function mousedown() {
  removeWarning()
}

function removeWarning() {
  if (warning !== null) {
    warning.remove()
    warning = null
  }
}

export { 
  checkIfOutOfRange,
  showWarning
}


import * as React from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { Button,
  Container,
  Box,
  Typography,
  Stack,
  Paper
  } from '@mui/material'
import { useEffect, useState } from 'react'
import { graphqlOperation, API } from 'aws-amplify'
import { getPriceV2, getCheckoutUrlV2 } from '../graphql/queries'
import { goCheckoutV2 } from '../graphql/mutations'
import CircularProgress from '@mui/material/CircularProgress'
import { QRCodeSVG } from 'qrcode.react'
import { getIsOnsite } from '../models/PathManager'


function Checkout() {

  const location = useLocation()
  const { projectId } = useParams()
  
  const [isOnsite, ] = useState(getIsOnsite(location.pathname))
  const [price, setPrice] = useState(null)
  const [currency, setCurrency] = useState(null)
  const [loading, setLoading] = useState(false)
  const [checkout, setCheckout] = useState(null)
  const [checkoutUrl, setCheckoutUrl] = useState(null)
  const [orderUrl, setOrderUrl] = useState("")

  function checkoutHandler(event) {
    console.log("checkoutHandler")
    goCheckout(projectId)
  }

  useEffect(() => {
    async function getPrice() {
      try {
        const value = await API.graphql(
          graphqlOperation(getPriceV2, { dir: projectId })
        )
        setPrice(value.data.getPriceV2.price.toLocaleString())
        setCurrency(value.data.getPriceV2.currency)
      } catch (err) {
        console.log('error getPriceV2') 
      }
    }
    getPrice()
  }, [projectId])

  useEffect(() => {
    setOrderUrl(window.location.protocol + "//" + window.location.host + "/order/" + projectId)
  }, [projectId])

  useEffect(() => {
    async function getCheckoutUrl() {
      try {
        const value = await API.graphql(
          graphqlOperation(getCheckoutUrlV2, { dir: projectId })
        )
        setCheckoutUrl(value.data.getCheckoutUrlV2)
      } catch (err) {
        console.log('error getCheckoutUrlV2') 
      }
    }
    if (projectId && price && currency) {
      getCheckoutUrl()
    }
  }, [projectId, price, currency])

  async function goCheckout(projectId) {
    setLoading(true)
    try {
      const value = await API.graphql(
        graphqlOperation(goCheckoutV2, { dir: projectId })
      )
      setCheckout(value.data.goCheckoutV2)
    } catch (err) {
      console.log('error goCheckoutV2') 
    }
    setLoading(false)
  }

  async function handleOpenCheckoutUrl(projectId) {
    window.open(checkoutUrl, '_blank')
  }

  return (
    <Container maxWidth="md">
      <Box 
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          height: '80vh'
        }}>
        <Stack
          alignItems="center"
          spacing={2}>
          { price ?
              <Typography
                variant="h3" 
                gutterBottom
                component="span">
                {price}&nbsp;{currency}
              </Typography>
            :
              ''
          }
          { loading ?
              <CircularProgress />
            :
              ''
          }
          { checkout ?
              <Paper elevation={24} 
                sx={{
                  px: 4,
                  py: 2,
                  textAlign: 'center',
                  borderRadius: 20,
                  border: '16px solid blue'
                }}>
                <Typography
                  component="div"
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '2rem'
                  }}>
                  {checkout.number}
                </Typography>
              </Paper>
            :
              ''
          }
          { isOnsite && price ?
              <>
                <Button 
                  onClick={checkoutHandler}
                  variant="contained"
                  color="success" 
                  sx={{ mt: 3 }}>
                  Pay
                </Button>
                <Typography variant="h5" component="div">
                  ボタンを押して決済端末で支払い
                </Typography>
                <Box sx={{
                  display: 'flex', 
                  flexDirection: 'column', 
                  alignItems: 'center',
                  mb: 2 }}>
                  <Typography variant="caption" component="div">
                    {orderUrl}
                  </Typography>
                  <Typography variant="h6" component="div">
                    ここからファイルをダウンロードできます。
                  </Typography>
                </Box>
                <QRCodeSVG value={orderUrl} />
              </>
            :
              ''
          }
          { !isOnsite && checkoutUrl ?
              <>
                <Button 
                  onClick={handleOpenCheckoutUrl}
                  variant="contained"
                  color="success" 
                  sx={{ mt: 3 }}>
                  Pay
                </Button>
                <Typography variant="h5" component="div">
                  ボタンを押すと配送先の指定と決済の画面が開きます
                </Typography>
              </>
            :
              ''
          }
        </Stack>
      </Box>
    </Container>
  );
}

export default Checkout;

 


import { constants } from './Constants'
import { Storage } from 'aws-amplify'


async function getHasImageStatus(projectId) {
  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  args.cacheControl = 'no-cache'
  args.validateObjectExistence = true
  try {
    await Storage.get(
      projectId + "/" + constants.PRINT_DIR + "/" + constants.STATUS_JSON, 
      args)
    return true
  } catch (err) {
    return false
  }
}


async function loadImageInfo(projectId) {
  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  args.cacheControl = 'no-cache'
  args.download = true
  try {
    const data = await Storage.get(
      projectId + "/" + constants.PRINT_DIR + "/" + constants.STATUS_JSON, 
      args)
      .then(response => {
        return response.Body.text()
      })
    return JSON.parse(data)
  } catch (err) {
    console.log('error loadImageInfo', err)
  }
}


async function getImageStatus(projectId) {
  try {
    const data = await loadImageInfo(projectId)
    /*
      {
        "started": "2021-10-21 21:55:04.233450",
        "completed": "2021-10-21 21:58:15.861096"
      }
    */
    const sorted = []
    for (var key in data) {
      sorted.push([key, data[key]])
    }
    sorted.sort(function(a, b) {
        return b[1] - a[1]
    })
    return sorted[0][0]
  } catch (err) {
    console.log('error getImageStatus', err)
  }
}


async function getImageUrls(projectId) {
  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  args.cacheControl = 'no-cache'
  args.download = false
  try {
    const top_svg_url = await Storage.get(
      projectId + "/" + constants.PRINT_DIR + '/' + constants.TOP_SVG,
      args
    )
    const bottom_svg_url = await Storage.get(
      projectId + "/" + constants.PRINT_DIR + '/' + constants.BOTTOM_SVG,
      args
    )
    const top_pdf_url = await Storage.get(
      projectId + "/" + constants.PRINT_DIR + '/' + constants.TOP_PDF,
      args
    )
    const bottom_pdf_url = await Storage.get(
      projectId + "/" + constants.PRINT_DIR + '/' + constants.BOTTOM_PDF,
      args
    )
    console.log('top_svg_url bottom_svg_url', top_svg_url, bottom_svg_url)
    console.log('top_pdf_url bottom_pdf_url', top_pdf_url, bottom_pdf_url)
    return {
      svg: {
        top: top_svg_url, 
        bottom: bottom_svg_url
      },
      pdf: {
        top: top_pdf_url, 
        bottom: bottom_pdf_url
      }
    }
  } catch (err) {
    console.log('error getImageUrls', err)
  }
}


export { getImageUrls, 
  getImageStatus,
  getHasImageStatus
}


import { useState, useEffect, useRef } from 'react'
import React from 'react';
import SVG from "@svgdotjs/svg.js/src/svg"


export default function Sourface(props) {
  const svg_ref = useRef(null)
  const [topSvg, setTopSvg] = useState('')
  
  useEffect(() => {
    async function doGet() {
      try {
        const _topSvg = await fetch(props.src).then(response => response.text())
        setTopSvg(_topSvg)
      } catch (err) {
        console.log('error fetch', err)
      }
    }
    console.log(props.src)
    doGet()
  }, [props])
      
  useEffect(() => {
    if (topSvg !== '') {
      const svg_container = SVG().addTo(svg_ref.current)
      var top = svg_container.svg(topSvg)
      if (typeof top.first().viewbox === 'function') {
        var xs = []
        var ys = []
        top.first().children().each(function(child, index) {
          if(child.type === 'g') {
            var bbox = child.bbox()
            if (bbox.x !== 0 && bbox.y !== 0) {
              xs.push(bbox.x)
              xs.push(bbox.x2)
              ys.push(bbox.y)
              ys.push(bbox.y2)
            }
            child.attr('style', null)
            child.attr('stroke-width', 50)
            child.attr('stroke-opacity', 1)
            child.attr('stroke-linecap', 'round')
            child.attr('stroke-linejoin', 'round')
            child.attr('stroke', '#cccccc')
            child.attr('fill', '#cccccc')
            child.attr('fill-opacity', 1)
            for (var gchild of child.children()) {
              if (gchild.type === 'g') {
                gchild.attr('style', null)
                gchild.attr('stroke-width', 50)
                gchild.attr('stroke-opacity', 1)
                gchild.attr('stroke-linecap', 'round')
                gchild.attr('stroke-linejoin', 'round')
                gchild.attr('stroke', '#cccccc')
                gchild.attr('fill', '#cccccc')
                gchild.attr('fill-opacity', 1)
                for (var ggchild of gchild.children()) {
                  if (ggchild.type === 'path') {
                    if ('style' in ggchild.attr()) {
                      ggchild.attr('style', null)
                      ggchild.attr('stroke-width', 50)
                      ggchild.attr('stroke-opacity', 1)
                      ggchild.attr('stroke-linecap', 'round')
                      ggchild.attr('stroke-linejoin', 'round')
                      ggchild.attr('stroke', '#cccccc')
                      ggchild.attr('fill', '#cccccc')
                      ggchild.attr('fill-opacity', 1)
                    }
                  }
                }
              }
            }
            // console.log(child.attr())
          }
        })
        var min_x = Math.min(...xs)
        var min_y = Math.min(...ys)
        var max_x = Math.max(...xs)
        var max_y = Math.max(...ys)
        var w = (max_x - min_x) + 5000
        var h = (max_y - min_y) + 7000
        top.first().viewbox(min_x - 2500, min_y - 2500, w, h)
        const RATE = 160
        top.first().width(w / RATE)
        top.first().height(h / RATE)
        svg_container.size(w / RATE, h / RATE)
      }
    }
  }, [topSvg])
  
  return (
    <div ref={svg_ref} />
  )
}

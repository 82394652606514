import { getNetwork } from './Network'
import { constants } from '../models/Constants'

const KEY_OPTIONS = [
  { type: 'head',                           text: 'Number' },
  { type: 'item',   value: 'KC_1',          text: '1 and !',      top: '!',  bottom: '1' },
  { type: 'item',   value: 'KC_2',          text: '2 and @',      top: '@',  bottom: '2' },
  { type: 'item',   value: 'KC_3',          text: '3 and #',      top: '#',  bottom: '3' },
  { type: 'item',   value: 'KC_4',          text: '4 and $',      top: '$',  bottom: '4' },
  { type: 'item',   value: 'KC_5',          text: '5 and %',      top: '%',  bottom: '5' },
  { type: 'item',   value: 'KC_6',          text: '6 and ^',      top: '^',  bottom: '6' },
  { type: 'item',   value: 'KC_7',          text: '7 and &',      top: '&',  bottom: '7' },
  { type: 'item',   value: 'KC_8',          text: '8 and *',      top: '*',  bottom: '8' },
  { type: 'item',   value: 'KC_9',          text: '9 and (',      top: '(',  bottom: '9' },
  { type: 'item',   value: 'KC_0',          text: '0 and )',      top: ')',  bottom: '0' },
  { type: 'head',                           text: 'Alphabet' },
  { type: 'item',   value: 'KC_A',          text: 'A',            top: '',   bottom: 'A', },
  { type: 'item',   value: 'KC_B',          text: 'B',            top: '',   bottom: 'B', },
  { type: 'item',   value: 'KC_C',          text: 'C',            top: '',   bottom: 'C', },
  { type: 'item',   value: 'KC_D',          text: 'D',            top: '',   bottom: 'D', },
  { type: 'item',   value: 'KC_E',          text: 'E',            top: '',   bottom: 'E', },
  { type: 'item',   value: 'KC_F',          text: 'F',            top: '',   bottom: 'F', },
  { type: 'item',   value: 'KC_G',          text: 'G',            top: '',   bottom: 'G', },
  { type: 'item',   value: 'KC_H',          text: 'H',            top: '',   bottom: 'H', },
  { type: 'item',   value: 'KC_I',          text: 'I',            top: '',   bottom: 'I', },
  { type: 'item',   value: 'KC_J',          text: 'J',            top: '',   bottom: 'J', },
  { type: 'item',   value: 'KC_K',          text: 'K',            top: '',   bottom: 'K', },
  { type: 'item',   value: 'KC_L',          text: 'L',            top: '',   bottom: 'L', },
  { type: 'item',   value: 'KC_M',          text: 'M',            top: '',   bottom: 'M', },
  { type: 'item',   value: 'KC_N',          text: 'N',            top: '',   bottom: 'N', },
  { type: 'item',   value: 'KC_O',          text: 'O',            top: '',   bottom: 'O', },
  { type: 'item',   value: 'KC_P',          text: 'P',            top: '',   bottom: 'P', },
  { type: 'item',   value: 'KC_Q',          text: 'Q',            top: '',   bottom: 'Q', },
  { type: 'item',   value: 'KC_R',          text: 'R',            top: '',   bottom: 'R', },
  { type: 'item',   value: 'KC_S',          text: 'S',            top: '',   bottom: 'S', },
  { type: 'item',   value: 'KC_T',          text: 'T',            top: '',   bottom: 'T', },
  { type: 'item',   value: 'KC_U',          text: 'U',            top: '',   bottom: 'U', },
  { type: 'item',   value: 'KC_V',          text: 'V',            top: '',   bottom: 'V', },
  { type: 'item',   value: 'KC_W',          text: 'W',            top: '',   bottom: 'W', },
  { type: 'item',   value: 'KC_X',          text: 'X',            top: '',   bottom: 'X', },
  { type: 'item',   value: 'KC_Y',          text: 'Y',            top: '',   bottom: 'Y', },
  { type: 'item',   value: 'KC_Z',          text: 'Z',            top: '',   bottom: 'Z', },
  { type: 'head',                           text: 'Controll' },
  { type: 'item',   value: 'KC_ENTER',      text: 'Return',       top: '',   bottom: 'return' },
  { type: 'item',   value: 'KC_ESCAPE',     text: 'Escape',       top: '',   bottom: 'esc' },
  { type: 'item',   value: 'KC_BACKSPACE',  text: 'Delete',       top: '',   bottom: 'del' },
  { type: 'item',   value: 'KC_TAB',        text: 'Tab',          top: '',   bottom: 'tab' },
  { type: 'item',   value: 'KC_LEFT_SHIFT', text: 'Shift',        top: '',   bottom: 'shift' },
  { type: 'item',   value: 'KC_LEFT_CTRL',  text: 'Ctl',          top: '',   bottom: 'ctl' },
  { type: 'item',   value: 'KC_LEFT_GUI',   text: 'Cmd',          top: '',   bottom: 'cmd' },
  { type: 'item',   value: 'KC_LEFT_ALT',   text: 'Alt',          top: '',   bottom: 'alt' },
  { type: 'item',   value: 'KC_CAPSLOCK',   text: 'Caps Lock',    top: '',   bottom: 'caps' },
  { type: 'item',   value: 'KC_NO',         text: '',             top: '',   bottom: '' },
  { type: 'item',   value: 'KC_SPACE',      text: 'Spacebar',     top: '',   bottom: '' },
  { type: 'head',                           text: 'Symbols' },
  { type: 'item',   value: 'KC_MINUS',      text: '- and _',      top: '_',  bottom: '-' },
  { type: 'item',   value: 'KC_EQUAL',      text: '= and +',      top: '+',  bottom: '=' },
  { type: 'item',   value: 'KC_LEFT_BRACKET',  text: '[ and {',   top: '{',  bottom: '[' },
  { type: 'item',   value: 'KC_RIGHT_BRACKET', text: '] and }',   top: '}',  bottom: ']' },
  { type: 'item',   value: 'KC_BACKSLASH',  text: '\\ and |',     top: '|',  bottom: '\\' },
  { type: 'item',   value: 'KC_NONUS_HASH', text: '# and ~',      top: '~',  bottom: '#' },
  { type: 'item',   value: 'KC_SEMICOLON',  text: '; and :',      top: ':',  bottom: ';' },
  { type: 'item',   value: 'KC_QUOTE',      text: '\' and "',     top: '"',  bottom: '\'' },
  { type: 'item',   value: 'KC_GRAVE',      text: '` and ~',      top: '~',  bottom: '`' },
  { type: 'item',   value: 'KC_COMMA',      text: ', and <',      top: '<',  bottom: ',' },
  { type: 'item',   value: 'KC_DOT',        text: '. and >',      top: '>',  bottom: '.' },
  { type: 'item',   value: 'KC_SLASH',      text: '/ and ?',      top: '?',  bottom: '/' },
  { type: 'head',                           text: 'Direction' },
  { type: 'item',   value: 'KC_RIGHT',      text: 'Right',        top: '',   bottom: 'right' },
  { type: 'item',   value: 'KC_LEFT',       text: 'Left',         top: '',   bottom: 'left' },
  { type: 'item',   value: 'KC_DOWN',       text: 'Down',         top: '',   bottom: 'down' },
  { type: 'item',   value: 'KC_UP',         text: 'Up',           top: '',   bottom: 'up' },
  { type: 'item',   value: 'KC_PAGE_UP',    text: 'Page Up',      top: '',   bottom: 'pgup' },
  { type: 'item',   value: 'KC_PAGE_DOWN',  text: 'Page Down',    top: '',   bottom: 'pgdn' }
]

const KEY_OPTIONS_DICT = {}

for (let info of KEY_OPTIONS) {
  KEY_OPTIONS_DICT[info.value] = info
}

const WIDTH_UNIT_OPTIONS = [
  { value: 0.25,  text: '0.25' },
  { value: 0.5,   text: '0.5' },
  { value: 0.75,  text: '0.75' },
  { value: 1,     text: '1' },
  { value: 1.25,  text: '1.25' },
  { value: 1.5,   text: '1.5' },
  { value: 1.75,  text: '1.75' },
  { value: 2,     text: '2' },
  { value: 2.25,  text: '2.25' },
  { value: 2.5,   text: '2.5' },
  { value: 2.75,  text: '2.75' },
  { value: 3,     text: '3' },
  // { value: 6.25,  text: '6.25' },
  // { value: 6.75,  text: '6.75' },
  // { value: 7,     text: '7' }
]

function getLayouts(
    keys, 
    KEY_START_X,
    KEY_START_Y,
    KEY_SCALE,
    type
  ) {
  // kbdataをアップデートする
  var layout = {keys: [], board: undefined}
  var minxs = []
  var minys = []
  var maxxs = []
  var maxys = []
  var x, y, w, h, bx, by, bw, bh = undefined
  // 2 means 
  // key width 16
  // key cap width 18
  // margin 1
  // (18 - 16) / 2 + margin = 2
  var x_offset = constants.KEYCAP_MARGIN + constants.BOARD_MARGIN_L
  var y_offset = constants.KEYCAP_MARGIN + constants.BOARD_MARGIN_L
  if (type === 2 || type === 7) {
    y_offset += constants.PIPICO_WIDTH + constants.MOD_KEY_MARGIN
  }
  // get arrangement of keys
  for (var key of keys) {
    x = (key.x() + (key.first().width() / 2) - KEY_START_X) / KEY_SCALE
    y = (key.y() + (key.first().height() / 2) - KEY_START_Y) / KEY_SCALE
    w = key.first().width() / KEY_SCALE
    h = key.first().height() / KEY_SCALE
    bx = x + x_offset
    by = y + y_offset
    bw = w + constants.KEYCAP_MARGIN * 2
    bh = h + constants.KEYCAP_MARGIN * 2
    var k = {
      'x': x,
      'y': y,
      'w': w,
      'h': h,
      'bx': bx,
      'by': by,
      'bw': bw,
      'bh': bh,
      'code': key.data('code'),
      'empty': key.data('empty'),
      'unit': key.data('unit')
    }
    layout.keys.push(k)
    minxs.push(x - w / 2)
    minys.push(y - h / 2)
    maxxs.push(x + w / 2)
    maxys.push(y + h / 2)
  }
  // set board size as min size of pcb.
  var minx = Math.min(...minxs)
  var miny = Math.min(...minys)
  var maxx = Math.max(...maxxs)
  var maxy = Math.max(...maxys)
  /* 
  if (type === 1) {
    bw = maxx + constants.KEYCAP_MARGIN * 2 + constants.BOARD_MARGIN_S * 2 + constants.MOD_KEY_MARGIN + constants.PIPICO_WIDTH
    bh = Math.max(maxy + constants.KEYCAP_MARGIN * 2, constants.PIPICO_HEIGHT) + constants.BOARD_MARGIN_S * 2
    bx = maxx + constants.KEYCAP_MARGIN * 2 + constants.BOARD_MARGIN_S * 2 + constants.PIPICO_WIDTH / 2
    by = constants.BOARD_MARGIN_S + constants.PIPICO_HEIGHT / 2
  } else if (type === 2) {
    bw = Math.max(maxx + constants.KEYCAP_MARGIN * 2, constants.PIPICO_HEIGHT) + constants.BOARD_MARGIN_S * 2
    bh = maxy + constants.KEYCAP_MARGIN * 2 + constants.BOARD_MARGIN_S * 2 + constants.MOD_KEY_MARGIN + constants.PIPICO_WIDTH
    bx = constants.BOARD_MARGIN_S + constants.PIPICO_HEIGHT / 2
    by = constants.BOARD_MARGIN_S + constants.PIPICO_WIDTH / 2
  }
  */
  if (type === 2 || type === 7) {
    bw = Math.max(maxx + constants.KEYCAP_MARGIN * 2, constants.PIPICO_HEIGHT) + constants.BOARD_MARGIN_L * 2
    bh = maxy + constants.KEYCAP_MARGIN * 2 + constants.BOARD_MARGIN_L * 2 + constants.MOD_KEY_MARGIN + constants.PIPICO_WIDTH
    bx = constants.BOARD_MARGIN_L + constants.PIPICO_HEIGHT / 2
    by = constants.BOARD_MARGIN_L + constants.PIPICO_WIDTH / 2
  } else if (type === 1 || type === 8) {
    bw = maxx + constants.KEYCAP_MARGIN * 2 + constants.BOARD_MARGIN_L * 2 + constants.MOD_KEY_MARGIN + constants.PIPICO_WIDTH
    bh = Math.max(maxy + constants.KEYCAP_MARGIN * 2, constants.PIPICO_HEIGHT) + constants.BOARD_MARGIN_L * 2
    bx = maxx + constants.KEYCAP_MARGIN * 2 + constants.BOARD_MARGIN_L * 2 + constants.PIPICO_WIDTH / 2 - 1
    by = constants.BOARD_MARGIN_L + constants.PIPICO_HEIGHT / 2
  }
  layout.board = {
    'w': parseInt(bw),
    'h': parseInt(bh),
    'key': {
      'w': maxx - minx,
      'h': maxy - miny
    },
    'module': {
      'x': bx,
      'y': by,
      'w': constants.PIPICO_WIDTH,
      'h': constants.PIPICO_HEIGHT
    }
  }
  return layout
}

/*
const MARGIN_UNIT_OPTIONS = [
  { value: 0,     text: '0' },
  { value: 0.25,  text: '0.25' },
  { value: 0.5,   text: '0.5' },
  { value: 0.75,  text: '0.75' },
  { value: 1,     text: '1' },
  { value: 1.25,  text: '1.25' },
  { value: 1.5,   text: '1.5' },
  { value: 1.75,  text: '1.75' },
  { value: 2,     text: '2' },
  { value: 2.25,  text: '2.25' },
  { value: 2.5,   text: '2.5' },
  { value: 2.75,  text: '2.75' },
  { value: 3,     text: '3' }
]
*/
function getData(
    type,
    keys, 
    KEY_START_X,
    KEY_START_Y,
    KEY_SCALE,
    KEY_SIZE,
    KEY_SPACE
  ) {
  // x y positon of keys for board arrangement
  var layouts = getLayouts(
    keys, 
    KEY_START_X,
    KEY_START_Y,
    KEY_SCALE,
    type
  )
  console.log(layouts)
  getNetwork(layouts.keys, type)
  const kbdata = {
    'version': 2,
    'type': type,
    'vars': {
      'key_size': KEY_SIZE,
      'key_space': KEY_SPACE,
      'key_scale': KEY_SCALE
    },
    'keys': layouts.keys,
    'board': layouts.board
  }
  return kbdata
}

export { getData, getLayouts, WIDTH_UNIT_OPTIONS, KEY_OPTIONS, KEY_OPTIONS_DICT } // MARGIN_UNIT_OPTIONS


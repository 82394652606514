import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import { 
  Container, 
  Button, 
  Box
} from '@mui/material'
import { setSvg, 
  setHiddenSvg,
  setEditorSize, 
  initEditor, 
  setOnSelectAction,
  modify_keycode,
  unselect_key,
  getSvg,
  getKeycodeResettedKbdata
} from '../svg/KeyboardEditor'
import { useParams } from 'react-router-dom'
import { ModifyDrawer } from './drawers/ModifyDrawer'
import { loadProject, saveProject, removeStatus } from '../models/ProjectManager'
import { useNavigate } from 'react-router-dom'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'


function KeymapEdit() {
  
  let { projectId } = useParams()
  let navigate = useNavigate()
  
  const [kbdata, setKbdata] = useState(null)
  const [modifyDrawerState, setModifyDrawerState] = useState(false)
  const [modifyData, setModifyData] = useState({})
  const svg_ref = useRef(null)
  const hidden_svg_ref = useRef(null)

  useEffect(() => {
    if (projectId !== undefined) {
      setSvg(svg_ref.current)
      setHiddenSvg(hidden_svg_ref.current)
    }
  }, [projectId])

  useEffect(() => {
    async function doGet() {
      const json_data = await loadProject(projectId)
      console.log(json_data)
      setKbdata(json_data)
      initEditor(json_data)
      setOnSelectAction(openModifyDrawer)
    }
    if (projectId !== undefined) {
      doGet()
    }
  }, [projectId])

  useEffect(() => {
    function handleResize() {
      setEditorSize()
    }
    window.addEventListener("resize", handleResize)
    handleResize()
    return () => window.removeEventListener("resize", handleResize)
  }, [])
  
  function handleSave(event) {
    const data = getKeycodeResettedKbdata(kbdata)
    setKbdata(data)
    saveProject(data, getSvg(data), projectId)
    removeStatus(projectId)
    console.log(data)
  }
  
  function toggleModifyDrawer() {
    setModifyDrawerState(!modifyDrawerState)
  }

  function openModifyDrawer(data) {
    console.log(data)
    setModifyData(data)
    setModifyDrawerState(true)
  }
  
  function closeModifyDrawer() {
    unselect_key()
    setModifyDrawerState(false)
  }
  
  function applyModify(data) {
    modify_keycode(data)
    closeModifyDrawer()
  }

  function handleGoProject() {
    handleSave()
    // status.jsonファイルの削除のために1秒待ってみる（効果があると思う）
    new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 1000)
    }).then(() => {
      navigate(navigate('/keymap/firm/' + projectId))
    })
  }
  
  return (
    <React.Fragment>
      <Container maxWidth="lg" sx={{ mt: 5 }}>
        <ModifyDrawer 
          state={modifyDrawerState} 
          applyHandler={applyModify}
          data={modifyData}
          toggleState={toggleModifyDrawer}
          closeHandler={closeModifyDrawer}
          keycodeOnly={true}
          />
        <Box
          sx={{
            width: '100%',
            height: 35,
            marginBottom: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Button onClick={handleGoProject} variant="outlined" endIcon={<NavigateNextIcon/>}>
            Proceed
          </Button>
        </Box>
      </Container>
      <div ref={svg_ref} />
      <Box sx={{ visibility: 'hidden' }}>
        <div ref={hidden_svg_ref} />
      </Box>
    </React.Fragment> 
  )
}


export default KeymapEdit



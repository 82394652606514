import { constants } from './Constants'
import { graphqlOperation, API, Storage } from 'aws-amplify'
import { getStlEnclosureV2 } from '../graphql/queries'


async function getHasEnclosureStatus(projectId) {
  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  args.cacheControl = 'no-cache'
  args.validateObjectExistence = true
  try {
    await Storage.get(
      projectId + "/" + constants.ENCLOSURE_DIR + "/" + constants.STATUS_JSON, 
      args)
    return true
  } catch (err) {
    return false
  }
}


async function loadEnclosureInfo(projectId) {
  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  args.cacheControl = 'no-cache'
  args.download = true
  try {
    const data = await Storage.get(
      projectId + '/' + constants.ENCLOSURE_DIR + '/' + constants.STATUS_JSON, 
      args)
      .then(response => {
        return response.Body.text()
      })
    return JSON.parse(data)
  } catch (err) {
    console.log('error loadEnclosureInfo', err)
  }
}


async function getEnclosureStatus(projectId) {
  try {
    const data = await loadEnclosureInfo(projectId)
    console.log('loadEnclosureInfo ', data)
    /*
      {
        "created": "2021-10-21 21:55:04.233450",
        "unrouted": "2021-10-21 21:55:05.979837",
        "dsn": "2021-10-21 21:55:08.326334",
        "session": "2021-10-21 21:58:01.885247",
        "routed": "2021-10-21 21:58:12.025637",
        "printfiles": "2021-10-21 21:58:15.861096",
        "completed"
      }
    */
    const sorted = []
    for (var key in data) {
      sorted.push([key, data[key]])
    }
    sorted.sort(function(a, b) {
        return b[1] - a[1]
    })
    return sorted[0][0]
  } catch (err) {
    console.log('error getEnclosureStatus', err)
  }
}


async function checkEnclosureUpdated(projectId) {
  let kbdata_last_modified = new Date(2000, 1, 2)
  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  try {
    const result = await Storage.getProperties(
      projectId + '/' + constants.KBDATA_JSON, 
      args)
    kbdata_last_modified = result.lastModified
  } catch (err) {
    console.log('error kbdata_last_modified') 
  }
  let enclosure_last_modified = new Date(2000, 1, 2)
  try {
    const result = await Storage.getProperties(
      projectId + '/' + constants.ENCLOSURE_DIR + '/' + constants.STATUS_JSON, 
      args)
    enclosure_last_modified = result.lastModified
  } catch (err) {
    console.log('error enclosure_last_modified') 
  }
  console.log('kbdata_last_modified ', kbdata_last_modified)
  console.log('enclosure_last_modified ', enclosure_last_modified)
  return kbdata_last_modified < enclosure_last_modified
}


async function generateEnclosure(projectId) {
  try {
    await API.graphql(
      graphqlOperation(
        getStlEnclosureV2, {
          dir: projectId
        }
      )
    )
  } catch (err) {
    console.log('error generateEnclosure') 
  }
}


async function getEnclosure(projectId) {
  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  args.cacheControl = 'no-cache'
  args.download = true
  try {
    const data = await Storage.get(
      projectId + '/' + constants.ENCLOSURE_DIR + '/' + constants.ENCLOSURE_STL,
      args
    )
    return data
  } catch (err) {
    console.log('error getEnclosure', err)
  }
}


export { getHasEnclosureStatus,
  getEnclosureStatus,
  checkEnclosureUpdated,
  generateEnclosure,
  getEnclosure
}
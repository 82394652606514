import { constants } from './Constants'
import { getStlV2 } from '../graphql/queries'
import { API, graphqlOperation, Storage } from 'aws-amplify';


async function getEnclosureStlFile(projectId) {
  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  args.cacheControl = 'no-cache'
  args.download = false
  try {
    const url = await Storage.get(
      projectId + '/' + constants.THREED_DIR + '/' + constants.ENCLOSURE_STL, 
      args)
    if (typeof url === "string") {
      return url
    }
  } catch (err) {
    console.log('error getEnclosureStlFile', err)
  }
}

async function getKeycapsStlFile(projectId) {
  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  args.cacheControl = 'no-cache'
  args.download = false
  try {
    const url = await Storage.get(
      projectId + '/' + constants.THREED_DIR + '/' + constants.KEYCAPS_STL,
      args
    )
    if (typeof url === "string") {
      return url
    }
  } catch (err) {
    console.log('error getKeycapsStlFile', err)
  }
}

async function getKeyswitchsStlFile(projectId) {
  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  args.cacheControl = 'no-cache'
  args.download = false
  try {
    const url = await Storage.get(
      projectId + '/' + constants.THREED_DIR + '/' + constants.KEYSWITCHS_STL,
      args
    )
    if (typeof url === "string") {
      return url
    }
  } catch (err) {
    console.log('error getKeyswitchsStlFile', err)
  }
}

async function getAxisStlFile(projectId) {
  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  args.cacheControl = 'no-cache'
  args.download = false
  try {
    const url = await Storage.get(
      projectId + '/' + constants.THREED_DIR + '/' + constants.AXIS_STL,
      args
    )
    if (typeof url === "string") {
      return url
    }
  } catch (err) {
    console.log('error getAxisStlFile', err)
  }
}

async function getModuleStlFile(projectId) {
  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  args.cacheControl = 'no-cache'
  args.download = false
  try {
    const url = await Storage.get(
      projectId + '/' + constants.THREED_DIR + '/' + constants.MODULE_STL,
      args
    )
    if (typeof url === "string") {
      return url
    }
  } catch (err) {
    console.log('error getModuleStlFile', err)
  }
}


async function getPinheaderPinStlFile(projectId) {
  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  args.cacheControl = 'no-cache'
  args.download = false
  try {
    const url = await Storage.get(
      projectId + '/' + constants.THREED_DIR + '/' + constants.PINHEADER_PIN_STL,
      args
    )
    if (typeof url === "string") {
      return url
    }
  } catch (err) {
    console.log('error getPinheaderPinStlFile', err)
  }
}


async function getPinheaderPlasticStlFile(projectId) {
  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  args.cacheControl = 'no-cache'
  args.download = false
  try {
    const url = await Storage.get(
      projectId + '/' + constants.THREED_DIR + '/' + constants.PINHEADER_PLASTIC_STL,
      args
    )
    if (typeof url === "string") {
      return url
    }
  } catch (err) {
    console.log('error getPinheaderPlasticStlFile', err)
  }
}


async function getUsbStlFile(projectId) {
  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  args.cacheControl = 'no-cache'
  args.download = false
  try {
    const url = await Storage.get(
      projectId + '/' + constants.THREED_DIR + '/' + constants.USB_STL,
      args
    )
    if (typeof url === "string") {
      return url
    }
  } catch (err) {
    console.log('error getUsbStlFile', err)
  }
}

async function getPcbStlFile(projectId) {
  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  args.cacheControl = 'no-cache'
  args.download = false
  try {
    const url = await Storage.get(
      projectId + '/' + constants.THREED_DIR + '/' + constants.PCB_STL,
      args
    )
    if (typeof url === "string") {
      return url
    }
  } catch (err) {
    console.log('error getPcbStlFile', err)
  }
}

async function getHasStlStatus(projectId) {
  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  args.cacheControl = 'no-cache'
  args.validateObjectExistence = true
  try {
    await Storage.get(
      projectId + "/" + constants.THREED_DIR + "/" + constants.STATUS_JSON, 
      args)
    return true
  } catch (err) {
    return false
  }
}


async function loadStlStatus(projectId) {
  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  args.cacheControl = 'no-cache'
  args.download = true
  try {
    const data = await Storage.get(
      projectId + "/" + constants.THREED_DIR + "/" + constants.STATUS_JSON, 
      args)
      .then(response => {
        return response.Body.text()
      })
    return JSON.parse(data)
  } catch (err) {
    console.log('error loadStlStatus', err)
  }
}


async function getStlStatus(projectId) {
  try {
    const data = await loadStlStatus(projectId)
    console.log('loadStlStatus ', data)
    /*
      {
        "started": "2021-10-21 21:55:04.233450",
        "completed": "2021-10-21 21:58:15.861096"
      }
    */
    const sorted = []
    for (var key in data) {
      sorted.push([key, data[key]])
    }
    sorted.sort(function(a, b) {
        return b[1] - a[1]
    })
    return sorted[0][0]
  } catch (err) {
    console.log('error getStlStatus', err)
  }
}


async function checkStlUpdated(projectId) {
  let kbdata_last_modified = new Date(2000, 1, 2)
  let stl_last_modified = new Date(2000, 1, 1)
  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  try {
    const result = await Storage.getProperties(
      projectId + '/' + constants.KBDATA_JSON, 
      args)
    kbdata_last_modified = result.lastModified
  } catch (err) {
    console.log('error kbdata_last_modified') 
  }

  try {
    const result = await Storage.getProperties(
      projectId + '/' + constants.THREED_DIR + '/' + constants.STATUS_JSON, 
      args)
    stl_last_modified = result.lastModified
  } catch (err) {
    console.log('error stl_last_modified') 
  }
  console.log('kbdata_last_modified ', kbdata_last_modified)
  console.log('stl_last_modified ', stl_last_modified)
  return kbdata_last_modified < stl_last_modified
}


async function generateStl(projectId) {
  try {
    await API.graphql(
      graphqlOperation(
        getStlV2, {
          dir: projectId
        }
      )
    )
    console.log('call getStlV2') 
  } catch (err) {
    console.log('error getStlV2') 
  }
}


export { getEnclosureStlFile, 
  getKeycapsStlFile,
  getKeyswitchsStlFile,
  getAxisStlFile,
  getModuleStlFile,
  getPinheaderPinStlFile,
  getPinheaderPlasticStlFile,
  getUsbStlFile,
  getPcbStlFile,
  getHasStlStatus,
  getStlStatus,
  checkStlUpdated,
  generateStl
}

import { useState } from 'react'
import React from 'react';
import { Box } from '@mui/material'
import Firmware from './Firmware'
import { useParams } from 'react-router-dom'
import Kbsvg from './Kbsvg'

function Firm() {
  
  const { projectId } = useParams()

  const [doneFirmware, setDoneFirmware] = useState(false)
  
  return (
    <Box>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        mb: 7 }}>
        <Kbsvg projectId={projectId} />
      </Box>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        mt: 10,
        mb: 6 }}>
        <Firmware projectId={projectId} setDone={setDoneFirmware} />
      </Box>
    </Box>
  )
}

export default Firm

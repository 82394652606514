import { useState, useEffect, useRef } from 'react'
import React from 'react';
import { 
  getHasFirmStatus,
  getFirmStatus,
  checkFirmUpdated,
  generateFirm,
  getFirm
} from '../models/FirmManager';
import { Button, 
  Box,
  Typography } from '@mui/material'
import fileDownload from 'js-file-download'
import CircularProgress from '@mui/material/CircularProgress'
import DownloadIcon from '@mui/icons-material/Download'


function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{props.label}</Typography>
      </Box>
    </Box>
  )
}


export default function Firmware(props) {
  
  const [projectId, ] = useState(props.projectId)
  const [firmStatus, setFirmStatus] = useState(null)
  const [firmFailed, setFirmFailed] = useState(false)
  const [firmWatching, setFirmWatching] = useState(false)
  const [firmGenerated, setFirmGenerated] = useState(false)
  
  let firmTimer = useRef()

  function getStatusWithProgress() {
    const status = [
      "started",
      "qmk",
      "completed"
    ]
    if (0 <= status.indexOf(firmStatus)) {
      return (status.indexOf(firmStatus) + 1) + '/' + status.length
    }
    return ""
  }
  
  function intervalFirmStatus() {
    getFirmStatus(projectId).then(data => {
      console.log('getFirmStatus', data)
      setFirmStatus(data)
    })
  }
  
  
  function callGenerateFirm() {
    generateFirm(projectId)
    setFirmWatching(true)
  }
  
  
  useEffect(() => {
    getHasFirmStatus(projectId).then(data => {
      console.log('getHasFirmStatus', data)
      if (data === true) {
        setFirmWatching(true)
      } else {
        // status.jsonは存在しないのでSTLの生成を行う。
        callGenerateFirm()
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId])
  
  
  useEffect(() => {
    if (firmWatching === true) {
      firmTimer.current = setInterval(intervalFirmStatus, 3000)
    } else {
      clearInterval(firmTimer.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firmWatching])
  
  
  useEffect(() => {
    console.log('firmStatus', firmStatus)
    if (firmStatus === "completed" || firmStatus === "error") {
      setFirmWatching(false)
      if (firmStatus === "completed") {
        setFirmGenerated(true)
      } else {
        setFirmFailed(true)
      }
    }
    /*
    if (firmStatus === "completed" || firmStatus === "error") {
      setFirmWatching(false)
      console.log('checkFirmUpdated')
      checkFirmUpdated(projectId).then(data => {
        console.log('checkFirmUpdated ' + data)
        if (data === false) {
          // 処理は終わっているが、status.jsonはkbdata.jsonより古いのでSTLファイルは古い情報で生成されたことになる
          // なので、再度STLの生成を行う。
          setFirmGenerated(false)
          callGenerateFirm()
        } else if (data === true) {
          if (firmStatus === "completed") {
            setFirmGenerated(true)
          } else {
            setFirmFailed(true)
          }
        }
      })
    }
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firmStatus])
  
  
  useEffect(() => {
    console.log("firmGenerated " + firmGenerated)
    props.setDone(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firmGenerated, props])
  
  
  // --------------------------------
  

  async function firmHandler(event) {
    if (firmGenerated) {
      const response = await getFirm(projectId)
      fileDownload(response.Body, 'firm.uf2')
    }
  }
  
  
  // --------------------------------
  
  
  return (
    <div>
      { firmGenerated ?
          <Button onClick={firmHandler} endIcon={<DownloadIcon />}>
            Firmware
          </Button>
        :
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center' }}>
            { !firmFailed ?
              <CircularProgressWithLabel label={getStatusWithProgress()} />
            :
              ""
            }
            { firmStatus && !firmFailed ?
              <Typography variant="caption" mt={0.4} mb={0.1} sx={{ color: 'text.secondary' }}>status: {firmStatus}</Typography>
            :
              ""
            }
            <Typography>Firmware</Typography>
            { firmFailed ?
              <Typography variant="caption" mt={0.4} mb={0.1} sx={{ color: 'text.secondary' }}>genetation failed</Typography>
            :
              ""
            }
          </Box>
      }
    </div>
  )
}

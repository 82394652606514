import { useState, useEffect, useRef } from 'react'
import React from 'react';
import { 
  getHasEnclosureStatus,
  getEnclosureStatus,
  checkEnclosureUpdated,
  generateEnclosure,
  getEnclosure
} from '../models/EnclosureManager';
import { Button, 
  Box,
  Typography } from '@mui/material'
import fileDownload from 'js-file-download'
import CircularProgress from '@mui/material/CircularProgress'
import DownloadIcon from '@mui/icons-material/Download'


function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{props.label}</Typography>
      </Box>
    </Box>
  )
}


export default function Enclosure(props) {
  
  const [projectId, ] = useState(props.projectId)
  const [enclosureStatus, setEnclosureStatus] = useState(null)
  const [enclosureFailed, setEnclosureFailed] = useState(false)
  const [enclosureWatching, setEnclosureWatching] = useState(false)
  const [enclosureGenerated, setEnclosureGenerated] = useState(false)
  
  let enclosureTimer = useRef()


  function getStatusWithProgress() {
    const status = [
      "started",
      "cad",
      "stl",
      "completed"
    ]
    if (0 <= status.indexOf(enclosureStatus)) {
      return (status.indexOf(enclosureStatus) + 1) + '/' + status.length
    }
    return ""
  }
  
  
  function intervalEnclosureStatus() {
    getEnclosureStatus(projectId).then(data => {
      console.log('getEnclosureStatus', data)
      setEnclosureStatus(data)
    })
  }
  
  
  function callGenerateEnclosure() {
    generateEnclosure(projectId)
    setEnclosureWatching(true)
  }
  
  
  useEffect(() => {
    getHasEnclosureStatus(projectId).then(data => {
      console.log('getHasEnclosureStatus', data)
      if (data === true) {
        setEnclosureWatching(true)
      } else {
        // status.jsonは存在しないのでSTLの生成を行う。
        callGenerateEnclosure()
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId])
  
  
  useEffect(() => {
    if (enclosureWatching === true) {
      enclosureTimer.current = setInterval(intervalEnclosureStatus, 3000)
    } else {
      clearInterval(enclosureTimer.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enclosureWatching])
  
  
  useEffect(() => {
    console.log('enclosureStatus', enclosureStatus)
    if (enclosureStatus === "completed" || enclosureStatus === "error") {
      setEnclosureWatching(false)
      if (enclosureStatus === "completed") {
        setEnclosureGenerated(true)
      } else {
        setEnclosureFailed(true)
      }
    }
    /*
    if (enclosureStatus === "completed" || enclosureStatus === "error") {
      console.log('checkEnclosureUpdated')
      setEnclosureWatching(false)
      checkEnclosureUpdated(projectId).then(data => {
        console.log('checkEnclosureUpdated ' + data)
        if (data === false) {
          // 処理は終わっているが、status.jsonはkbdata.jsonより古いのでSTLファイルは古い情報で生成されたことになる
          // なので、再度STLの生成を行う。
          setEnclosureGenerated(false)
          callGenerateEnclosure()
        } else if (data === true) {
          if (enclosureStatus === "completed") {
            setEnclosureGenerated(true)
          } else {
            setEnclosureFailed(true)
          }
        }
      })
    }
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enclosureStatus])
  
  
  useEffect(() => {
    console.log("enclosureGenerated " + enclosureGenerated)
    props.setDone(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enclosureGenerated, props])
  
  
  // --------------------------------
  

  async function enclosureHandler(event) {
    if (enclosureGenerated) {
      const response = await getEnclosure(projectId)
      fileDownload(response.Body, 'enclosure.stl')
    }
  }
  
  
  // --------------------------------
  
  
  return (
    <div>
      { enclosureGenerated ?
          <Button onClick={enclosureHandler} endIcon={<DownloadIcon />}>
            Enclosure
          </Button>
        :
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center' }}>
            { !enclosureFailed ?
              <CircularProgressWithLabel label={getStatusWithProgress()} />
            :
              ""
            }
            { enclosureStatus && !enclosureFailed ?
              <Typography variant="caption" mt={0.4} mb={0.1} sx={{ color: 'text.secondary' }}>status: {enclosureStatus}</Typography>
            :
              ""
            }
            <Typography>Enclosure</Typography>
            { enclosureFailed ?
              <Typography variant="caption" mt={0.4} mb={0.1} sx={{ color: 'text.secondary' }}>genetation failed</Typography>
            :
              ""
            }
          </Box>
      }
    </div>
  )
}

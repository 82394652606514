
function getNetwork(keys, type) {

    /*
        ┌──────────┐
   gp0 ─┤ 1     40 ├─ vbus
   gp1 ─┤ 2     39 ├─ vsys
  gnd0 ─┤ 3     38 ├─ gnd7
   gp2 ─┤ 4     37 ├─ 3v3_en
   gp3 ─┤ 5     36 ├─ 3v3
   gp4 ─┤ 6     35 ├─ vref
   gp5 ─┤ 7     34 ├─ gp28
  gnd1 ─┤ 8     33 ├─ gnd6
   gp6 ─┤ 9     32 ├─ gp27
   gp7 ─┤ 10    31 ├─ gp26
   gp8 ─┤ 11    30 ├─ run
   gp9 ─┤ 12    29 ├─ gp22
  gnd2 ─┤ 13    28 ├─ gnd5
  gp10 ─┤ 14    27 ├─ gp21
  gp11 ─┤ 15    26 ├─ gp20
  gp12 ─┤ 16    25 ├─ gp19
  gp13 ─┤ 17    24 ├─ gp18
  gnd3 ─┤ 18    23 ├─ gnd4
  gp14 ─┤ 19    22 ├─ gp17
  gp15 ─┤ 20    21 ├─ gp16
        └──────────┘
    */
    /*
    const pico_net = [
        "GP0", // 1
        "GP1", // 2
        "GND0", // 3
        "GP2", // 4
        "GP3", // 5
        "GP4", // 6
        "GP5", // 7
        "GND1", // 8
        "GP6", // 9
        "GP7", // 10
        "GP8", // 11
        "GP9", // 12
        "GND2", // 13
        "GP10", // 14
        "GP11", // 15
        "GP12", // 16
        "GP13", // 17
        "GND3", // 18
        "GP14", // 19
        "GP15", // 20
        "GP16", // 21
        "GP17", // 22
        "GND4", // 23
        "GP18", // 24
        "GP19", // 25
        "GP20", // 26
        "GP21", // 27
        "GND5", // 28
        "GP22", // 29
        "RUN", // 30
        "GP26", // 31
        "GP27", // 32
        "GND6", // 33
        "GP28", // 34
        "VREF", // 35
        "3V3", // 36
        "3V3_EN", // 37
        "GND7", // 38
        "VSYS", // 39
        "VBUS" // 40
    ]
    */
    /*
    Type1
         col 1 col 2 col 3 col 4
        ┌─────┬─────┬─────┬─────┐
  row 1 │  1  │  2  │  3  │  4  │
        ├─────┼─────┼─────┼─────┤
  row 2 │  5  │  6  │  7  │  8  │
        ├─────┼─────┼─────┼─────┤
  row 3 │  9  │  10 │  11 │  12 │
        ├─────┼─────┼─────┼─────┤
  row 4 │  13 │  14 │  15 │  16 │
        └─────┴─────┴─────┴─────┘

    ┌─────┬─────┬─────┬────┬────┐
    │ num │ Net1│ Net2│  X │  Y │
    ├─────┼─────┼─────┼────┼────┤
    │  1  │ gp0 │ gnd0│  8 │  8 │
    ├─────┼─────┼─────┼────┼────┤
    │  2  │ gp4 │ gnd0│ 27 │  8 │
    ├─────┼─────┼─────┼────┼────┤
    │  3  │ gp3 │ gnd0│ 46 │  8 │
    ├─────┼─────┼─────┼────┼────┤
    │  4  │ gp2 │ gnd0│ 65 │  8 │
    ├─────┼─────┼─────┼────┼────┤
    │  5  │ gp6 │ gnd2│  8 │ 27 │
    ├─────┼─────┼─────┼────┼────┤
    │  6  │ gp7 │ gnd2│ 27 │ 27 │
    ├─────┼─────┼─────┼────┼────┤
    │  7  │ gp8 │ gnd2│ 46 │ 27 │
    ├─────┼─────┼─────┼────┼────┤
    │  8  │ gp9 │ gnd2│ 65 │ 27 │
    ├─────┼─────┼─────┼────┼────┤
    │  9  │ gp10│ gnd3│  8 │ 46 │
    ├─────┼─────┼─────┼────┼────┤
    │  10 │ gp11│ gnd3│ 27 │ 46 │
    ├─────┼─────┼─────┼────┼────┤
    │  11 │ gp12│ gnd3│ 46 │ 46 │
    ├─────┼─────┼─────┼────┼────┤
    │  12 │ gp13│ gnd3│ 65 │ 46 │
    ├─────┼─────┼─────┼────┼────┤
    │  13 │ gp21│ gnd4│  8 │ 65 │
    ├─────┼─────┼─────┼────┼────┤
    │  14 │ gp20│ gnd4│ 27 │ 65 │
    ├─────┼─────┼─────┼────┼────┤
    │  15 │ gp19│ gnd4│ 46 │ 65 │
    ├─────┼─────┼─────┼────┼────┤
    │  16 │ gp18│ gnd4│ 65 │ 65 │
    └─────┴─────┴─────┴────┴────┘
    */

    // Front

    const type1_net = [
        ["GND0",  "GP0",  8,  8],
        ["GND0",  "GP4", 27,  8],
        ["GND0",  "GP3", 46,  8],
        ["GND0",  "GP2", 65,  8],
        ["GND2",  "GP6",  8, 27],
        ["GND2",  "GP7", 27, 27],
        ["GND2",  "GP8", 46, 27],
        ["GND2",  "GP9", 65, 27],
        ["GND3", "GP10",  8, 46],
        ["GND3", "GP11", 27, 46],
        ["GND3", "GP12", 46, 46],
        ["GND3", "GP13", 65, 46],
        ["GND4", "GP21",  8, 65],
        ["GND4", "GP20", 27, 65],
        ["GND4", "GP19", 46, 65],
        ["GND4", "GP18", 65, 65]
    ]

    /*
  Type2
         col 1 col 2 col 3 col 4 col 5
        ┌─────┬─────┬─────┬─────┬─────┐
  row 1 │  1  │  4  │  7  │  10 │  13 │
        ├─────┼─────┼─────┼─────┼─────┤
  row 2 │  2  │  5  │  8  │  11 │  14 │
        ├─────┼─────┼─────┼─────┼─────┤
  row 3 │  3  │  6  │  9  │  12 │  15 │
        └─────┴─────┴─────┴─────┴─────┘

    ┌─────┬─────┬─────┬────┬────┐
    │ num │ Net1│ Net2│  X │  Y │
    ├─────┼─────┼─────┼────┼────┤
    │  1  │ gp2 │ gnd1│  8 │  8 │
    ├─────┼─────┼─────┼────┼────┤
    │  2  │ gp1 │ gnd1│  8 │ 27 │
    ├─────┼─────┼─────┼────┼────┤
    │  3  │ gp0 │ gnd1│  8 │ 46 │
    ├─────┼─────┼─────┼────┼────┤
    │  4  │ gp9 │ gnd2│ 27 │  8 │
    ├─────┼─────┼─────┼────┼────┤
    │  5  │ gp8 │ gnd2│ 27 │ 27 │
    ├─────┼─────┼─────┼────┼────┤
    │  6  │ gp7 │ gnd2│ 27 │ 46 │
    ├─────┼─────┼─────┼────┼────┤
    │  7  │ gp13│ gnd3│ 46 │  8 │
    ├─────┼─────┼─────┼────┼────┤
    │  8  │ gp12│ gnd3│ 46 │ 27 │
    ├─────┼─────┼─────┼────┼────┤
    │  9  │ gp11│ gnd3│ 46 │ 46 │
    ├─────┼─────┼─────┼────┼────┤
    │  10 │ gp22│ gnd5│ 65 │  8 │
    ├─────┼─────┼─────┼────┼────┤
    │  11 │ gp26│ gnd5│ 65 │ 27 │
    ├─────┼─────┼─────┼────┼────┤
    │  12 │ gp27│ gnd5│ 65 │ 46 │
    ├─────┼─────┼─────┼────┼────┤
    │  13 │ gp18│ gnd4│ 84 │  8 │
    ├─────┼─────┼─────┼────┼────┤
    │  14 │ gp19│ gnd4│ 84 │ 27 │
    ├─────┼─────┼─────┼────┼────┤
    │  15 │ gp20│ gnd4│ 84 │ 46 │
    └─────┴─────┴─────┴────┴────┘
    */

    // Front

    const type2_net = [
        ["GND1",  "GP2",  8,  8],
        ["GND1",  "GP1",  8, 27],
        ["GND1",  "GP0",  8, 46],
        ["GND2",  "GP9", 27,  8],
        ["GND2",  "GP8", 27, 27],
        ["GND2",  "GP7", 27, 46],
        ["GND3", "GP13", 46,  8],
        ["GND3", "GP12", 46, 27],
        ["GND3", "GP11", 46, 46],
        ["GND5", "GP22", 65,  8],
        ["GND5", "GP26", 65, 27],
        ["GND5", "GP27", 65, 46],
        ["GND4", "GP18", 84,  8],
        ["GND4", "GP19", 84, 27],
        ["GND4", "GP20", 84, 46]
    ]

    /*
    Type3
         col 1 col 2 col 3 col 4
        ┌─────┬─────┬─────┬─────┐
  row 1 │  1  │  2  │  3  │  4  │
        ├─────┼─────┼─────┼─────┤
  row 2 │  5  │  6  │  7  │  8  │
        ├─────┼─────┼─────┼─────┤
  row 3 │  9  │  10 │  11 │  12 │
        ├─────┼─────┼─────┼─────┤
  row 4 │  13 │  14 │  15 │  16 │
        └─────┴─────┴─────┴─────┘

    ┌─────┬─────┬─────┬────┬────┐
    │ num │ Net1│ Net2│  X │  Y │
    ├─────┼─────┼─────┼────┼────┤
    │  1  │ gp28│ gnd7│  8 │  8 │
    ├─────┼─────┼─────┼────┼────┤
    │  2  │ gp27│ gnd7│ 27 │  8 │
    ├─────┼─────┼─────┼────┼────┤
    │  3  │ gp26│ gnd7│ 46 │  8 │
    ├─────┼─────┼─────┼────┼────┤
    │  4  │ gp22│ gnd7│ 65 │  8 │
    ├─────┼─────┼─────┼────┼────┤
    │  5  │ gp21│ gnd5│  8 │ 27 │
    ├─────┼─────┼─────┼────┼────┤
    │  6  │ gp20│ gnd5│ 27 │ 27 │
    ├─────┼─────┼─────┼────┼────┤
    │  7  │ gp19│ gnd5│ 46 │ 27 │
    ├─────┼─────┼─────┼────┼────┤
    │  8  │ gp18│ gnd5│ 65 │ 27 │
    ├─────┼─────┼─────┼────┼────┤
    │  9  │ gp17│ gnd4│  8 │ 46 │
    ├─────┼─────┼─────┼────┼────┤
    │  10 │ gp16│ gnd4│ 27 │ 46 │
    ├─────┼─────┼─────┼────┼────┤
    │  11 │ gp8 │ gnd4│ 46 │ 46 │
    ├─────┼─────┼─────┼────┼────┤
    │  12 │ gp9 │ gnd4│ 65 │ 46 │
    ├─────┼─────┼─────┼────┼────┤
    │  13 │ gp10│ gnd2│  8 │ 65 │
    ├─────┼─────┼─────┼────┼────┤
    │  14 │ gp11│ gnd2│ 27 │ 65 │
    ├─────┼─────┼─────┼────┼────┤
    │  15 │ gp12│ gnd2│ 46 │ 65 │
    ├─────┼─────┼─────┼────┼────┤
    │  16 │ gp13│ gnd2│ 65 │ 65 │
    └─────┴─────┴─────┴────┴────┘
    */

    // Front
    /*
    const type3_net = [
        ["GND7", "GP28",  8,  8],
        ["GND7", "GP27", 27,  8],
        ["GND7", "GP26", 46,  8],
        ["GND7", "GP22", 65,  8],
        ["GND5", "GP21",  8, 27],
        ["GND5", "GP20", 27, 27],
        ["GND5", "GP19", 46, 27],
        ["GND5", "GP18", 65, 27],
        ["GND4", "GP17",  8, 46],
        ["GND4", "GP16", 27, 46],
        ["GND4",  "GP8", 46, 46],
        ["GND4",  "GP9", 65, 46],
        ["GND2", "GP10",  8, 65],
        ["GND2", "GP11", 27, 65],
        ["GND2", "GP12", 46, 65],
        ["GND2", "GP13", 65, 65]
    ]
    */
    /*
  Type4
         col 1 col 2 col 3 col 4 col 5
        ┌─────┬─────┬─────┬─────┬─────┐
  row 1 │  1  │  4  │  7  │  10 │  13 │
        ├─────┼─────┼─────┼─────┼─────┤
  row 2 │  2  │  5  │  8  │  11 │  14 │
        ├─────┼─────┼─────┼─────┼─────┤
  row 3 │  3  │  6  │  9  │  12 │  15 │
        └─────┴─────┴─────┴─────┴─────┘

    ┌─────┬─────┬─────┬────┬────┐
    │ num │ Net1│ Net2│  X │  Y │
    ├─────┼─────┼─────┼────┼────┤
    │  1  │ gp11│ gnd2│  8 │  8 │
    ├─────┼─────┼─────┼────┼────┤
    │  2  │ gp12│ gnd2│  8 │ 27 │
    ├─────┼─────┼─────┼────┼────┤
    │  3  │ gp13│ gnd2│  8 │ 46 │
    ├─────┼─────┼─────┼────┼────┤
    │  4  │ gp7 │ gnd1│ 27 │  8 │
    ├─────┼─────┼─────┼────┼────┤
    │  5  │ gp8 │ gnd1│ 27 │ 27 │
    ├─────┼─────┼─────┼────┼────┤
    │  6  │ gp9 │ gnd1│ 27 │ 46 │
    ├─────┼─────┼─────┼────┼────┤
    │  7  │ gp17│ gnd4│ 46 │  8 │
    ├─────┼─────┼─────┼────┼────┤
    │  8  │ gp16│ gnd4│ 46 │ 27 │
    ├─────┼─────┼─────┼────┼────┤
    │  9  │ gp5 │ gnd4│ 46 │ 46 │
    ├─────┼─────┼─────┼────┼────┤
    │  10 │ gp20│ gnd5│ 65 │  8 │
    ├─────┼─────┼─────┼────┼────┤
    │  11 │ gp19│ gnd5│ 65 │ 27 │
    ├─────┼─────┼─────┼────┼────┤
    │  12 │ gp18│ gnd5│ 65 │ 46 │
    ├─────┼─────┼─────┼────┼────┤
    │  13 │ gp28│ gnd7│ 84 │  8 │
    ├─────┼─────┼─────┼────┼────┤
    │  14 │ gp27│ gnd7│ 84 │ 27 │
    ├─────┼─────┼─────┼────┼────┤
    │  15 │ gp26│ gnd7│ 84 │ 46 │
    └─────┴─────┴─────┴────┴────┘
    */

    // Front
    /*
    const type4_net = [
        ["GND2", "GP11",  8,  8],
        ["GND2", "GP12",  8, 27],
        ["GND2", "GP13",  8, 46],
        ["GND1",  "GP7", 27,  8],
        ["GND1",  "GP8", 27, 27],
        ["GND1",  "GP9", 27, 46],
        ["GND4", "GP17", 46,  8],
        ["GND4", "GP16", 46, 27],
        ["GND4",  "GP5", 46, 46],
        ["GND5", "GP20", 65,  8],
        ["GND5", "GP19", 65, 27],
        ["GND5", "GP18", 65, 46],
        ["GND7", "GP28", 84,  8],
        ["GND7", "GP27", 84, 27],
        ["GND7", "GP26", 84, 46]
    ]
    */

    /*
  Type7
         col 1 col 2 col 3 col 4 col 5
        ┌─────┬─────┬─────┬─────┬─────┐
  row 1 │  1  │  6  │  11 │  16 │  21 │
        ├─────┼─────┼─────┼─────┼─────┤
  row 2 │  2  │  7  │  12 │  17 │  22 │
        ├─────┼─────┼─────┼─────┼─────┤
  row 3 │  3  │  8  │  13 │  18 │  23 │
        ├─────┼─────┼─────┼─────┼─────┤
  row 4 │  4  │  9  │  14 │  19 │  24 │
        ├─────┼─────┼─────┼─────┼─────┤
  row 5 │  5  │  10 │  15 │  20 │  25 │
        └─────┴─────┴─────┴─────┴─────┘

    ┌─────┬─────┬─────┬────┬────┐
    │ num │ Net1│ Net2│  X │  Y │
    ├─────┼─────┼─────┼────┼────┤
    │  1  │ gp4 │ gnd5│  8 │  8 │
    ├─────┼─────┼─────┼────┼────┤
    │  2  │ gp3 │ gnd5│  8 │ 27 │
    ├─────┼─────┼─────┼────┼────┤
    │  3  │ gp2 │ gnd5│  8 │ 46 │
    ├─────┼─────┼─────┼────┼────┤
    │  4  │ gp1 │ gnd5│  8 │ 65 │
    ├─────┼─────┼─────┼────┼────┤
    │  5  │ gp0 │ gnd5│  8 │ 84 │
    ├─────┼─────┼─────┼────┼────┤
    │  6  │ gp9 │ gnd5│ 27 │  8 │
    ├─────┼─────┼─────┼────┼────┤
    │  7  │ gp8 │ gnd5│ 27 │ 27 │
    ├─────┼─────┼─────┼────┼────┤
    │  8  │ gp7 │ gnd5│ 27 │ 46 │
    ├─────┼─────┼─────┼────┼────┤
    │  9  │ gp6 │ gnd5│ 27 │ 65 │
    ├─────┼─────┼─────┼────┼────┤
    │  10 │ gp5 │ gnd5│ 27 │ 84 │
    ├─────┼─────┼─────┼────┼────┤
    │  11 │ gp14│ gnd5│ 46 │  8 │
    ├─────┼─────┼─────┼────┼────┤
    │  12 │ gp13│ gnd5│ 46 │ 27 │
    ├─────┼─────┼─────┼────┼────┤
    │  13 │ gp12│ gnd5│ 46 │ 46 │
    ├─────┼─────┼─────┼────┼────┤
    │  14 │ gp11│ gnd5│ 46 │ 65 │
    ├─────┼─────┼─────┼────┼────┤
    │  15 │ gp10│ gnd5│ 46 │ 84 │
    ├─────┼─────┼─────┼────┼────┤
    │  16 │ gp21│ gnd5│ 65 │  8 │
    ├─────┼─────┼─────┼────┼────┤
    │  17 │ gp22│ gnd5│ 65 │ 27 │
    ├─────┼─────┼─────┼────┼────┤
    │  18 │ gp26│ gnd5│ 65 │ 46 │
    ├─────┼─────┼─────┼────┼────┤
    │  19 │ gp27│ gnd5│ 65 │ 65 │
    ├─────┼─────┼─────┼────┼────┤
    │  20 │ gp28│ gnd5│ 65 │ 84 │
    ├─────┼─────┼─────┼────┼────┤
    │  21 │ gp16│ gnd5│ 84 │  8 │
    ├─────┼─────┼─────┼────┼────┤
    │  22 │ gp17│ gnd5│ 84 │ 27 │
    ├─────┼─────┼─────┼────┼────┤
    │  23 │ gp18│ gnd5│ 84 │ 46 │
    ├─────┼─────┼─────┼────┼────┤
    │  24 │ gp19│ gnd5│ 84 │ 65 │
    ├─────┼─────┼─────┼────┼────┤
    │  25 │ gp20│ gnd5│ 84 │ 84 │
    └─────┴─────┴─────┴────┴────┘
    */

    // Front
    const type7_net = [
        ["GND4",  "GP4",  8,  8],
        ["GND4",  "GP3",  8, 27],
        ["GND4",  "GP2",  8, 46],
        ["GND4",  "GP1",  8, 65],
        ["GND4",  "GP0",  8, 84],
        ["GND4",  "GP9", 27,  8],
        ["GND4",  "GP8", 27, 27],
        ["GND4",  "GP7", 27, 46],
        ["GND4",  "GP6", 27, 65],
        ["GND4",  "GP5", 27, 84],
        ["GND4", "GP14", 46,  8],
        ["GND4", "GP13", 46, 27],
        ["GND4", "GP12", 46, 46],
        ["GND4", "GP11", 46, 65],
        ["GND4", "GP10", 46, 84],
        ["GND4", "GP21", 65,  8],
        ["GND4", "GP22", 65, 27],
        ["GND4", "GP26", 65, 46],
        ["GND4", "GP27", 65, 65],
        ["GND4", "GP28", 65, 84],
        ["GND4", "GP16", 84,  8],
        ["GND4", "GP17", 84, 27],
        ["GND4", "GP18", 84, 46],
        ["GND4", "GP19", 84, 65],
        ["GND4", "GP20", 84, 84]
    ]
    
    /*
  Type8
         col 1 col 2 col 3 col 4 col 5
        ┌─────┬─────┬─────┬─────┬─────┐
  row 1 │  1  │  2  │  3  │  4  │  5  │
        ├─────┼─────┼─────┼─────┼─────┤
  row 2 │  6  │  7  │  8  │  9  │  10 │
        ├─────┼─────┼─────┼─────┼─────┤
  row 3 │  11 │  12 │  13 │  14 │  15 │
        ├─────┼─────┼─────┼─────┼─────┤
  row 4 │  16 │  17 │  18 │  19 │  20 │
        ├─────┼─────┼─────┼─────┼─────┤
  row 5 │  21 │  22 │  23 │  24 │  25 │
        └─────┴─────┴─────┴─────┴─────┘

    ┌─────┬─────┬─────┬────┬────┐
    │ num │ Net1│ Net2│  X │  Y │
    ├─────┼─────┼─────┼────┼────┤
    │  1  │ gp0 │ gnd4│  8 │  8 │
    ├─────┼─────┼─────┼────┼────┤
    │  2  │ gp1 │ gnd4│ 27 │  8 │
    ├─────┼─────┼─────┼────┼────┤
    │  3  │ gp2 │ gnd4│ 46 │  8 │
    ├─────┼─────┼─────┼────┼────┤
    │  4  │ gp3 │ gnd4│ 65 │  8 │
    ├─────┼─────┼─────┼────┼────┤
    │  5  │ gp4 │ gnd4│ 84 │  8 │
    ├─────┼─────┼─────┼────┼────┤
    │  6  │ gp5 │ gnd4│  8 │ 27 │
    ├─────┼─────┼─────┼────┼────┤
    │  7  │ gp6 │ gnd4│ 27 │ 27 │
    ├─────┼─────┼─────┼────┼────┤
    │  8  │ gp7 │ gnd4│ 46 │ 27 │
    ├─────┼─────┼─────┼────┼────┤
    │  9  │ gp8 │ gnd4│ 65 │ 27 │
    ├─────┼─────┼─────┼────┼────┤
    │  10 │ gp9 │ gnd4│ 84 │ 27 │
    ├─────┼─────┼─────┼────┼────┤
    │  11 │ gp10│ gnd4│  8 │ 46 │
    ├─────┼─────┼─────┼────┼────┤
    │  12 │ gp11│ gnd4│ 27 │ 46 │
    ├─────┼─────┼─────┼────┼────┤
    │  13 │ gp12│ gnd4│ 46 │ 46 │
    ├─────┼─────┼─────┼────┼────┤
    │  14 │ gp13│ gnd4│ 65 │ 46 │
    ├─────┼─────┼─────┼────┼────┤
    │  15 │ gp14│ gnd4│ 84 │ 46 │
    ├─────┼─────┼─────┼────┼────┤
    │  16 │ gp28│ gnd4│  8 │ 65 │
    ├─────┼─────┼─────┼────┼────┤
    │  17 │ gp27│ gnd4│ 27 │ 65 │
    ├─────┼─────┼─────┼────┼────┤
    │  18 │ gp26│ gnd4│ 46 │ 65 │
    ├─────┼─────┼─────┼────┼────┤
    │  19 │ gp22│ gnd4│ 65 │ 65 │
    ├─────┼─────┼─────┼────┼────┤
    │  20 │ gp21│ gnd4│ 84 │ 65 │
    ├─────┼─────┼─────┼────┼────┤
    │  21 │ gp20│ gnd4│  8 │ 84 │
    ├─────┼─────┼─────┼────┼────┤
    │  22 │ gp19│ gnd4│ 27 │ 84 │
    ├─────┼─────┼─────┼────┼────┤
    │  23 │ gp18│ gnd4│ 46 │ 84 │
    ├─────┼─────┼─────┼────┼────┤
    │  24 │ gp17│ gnd4│ 65 │ 84 │
    ├─────┼─────┼─────┼────┼────┤
    │  25 │ gp16│ gnd4│ 84 │ 84 │
    └─────┴─────┴─────┴────┴────┘
    */

    // Front
    /*
    const type8_net = [
        [ "GP0", "GND4",  8,  8],
        [ "GP1", "GND4", 27,  8],
        [ "GP2", "GND4", 46,  8],
        [ "GP3", "GND4", 65,  8],
        [ "GP4", "GND4", 84,  8],
        [ "GP5", "GND4",  8, 27],
        [ "GP6", "GND4", 27, 27],
        [ "GP7", "GND4", 46, 27],
        [ "GP8", "GND4", 65, 27],
        [ "GP9", "GND4", 84, 27],
        ["GP10", "GND4",  8, 46],
        ["GP11", "GND4", 27, 46],
        ["GP12", "GND4", 46, 46],
        ["GP13", "GND4", 65, 46],
        ["GP14", "GND4", 84, 46],
        ["GP28", "GND4",  8, 65],
        ["GP27", "GND4", 27, 65],
        ["GP26", "GND4", 46, 65],
        ["GP22", "GND4", 65, 65],
        ["GP21", "GND4", 84, 65],
        ["GP20", "GND4",  8, 84],
        ["GP19", "GND4", 27, 84],
        ["GP18", "GND4", 46, 84],
        ["GP17", "GND4", 65, 84],
        ["GP16", "GND4", 84, 84]
    ]
    */
    // Front
    const type8_net = [
        [ "GP0", "GND4",  8,  8],
        [ "GP1", "GND4", 27,  8],
        [ "GP2", "GND4", 46,  8],
        [ "GP3", "GND4", 65,  8],
        [ "GP4", "GND4", 84,  8],
        [ "GP5", "GND4",  8, 27],
        [ "GP6", "GND4", 27, 27],
        [ "GP7", "GND4", 46, 27],
        [ "GP8", "GND4", 65, 27],
        [ "GP9", "GND4", 84, 27],
        ["GP10", "GND4",  8, 46],
        ["GP11", "GND4", 27, 46],
        ["GP12", "GND4", 46, 46],
        ["GP13", "GND4", 65, 46],
        ["GP14", "GND4", 84, 46],
        ["GP28", "GND4",  8, 65],
        ["GP27", "GND4", 27, 65],
        ["GP26", "GND4", 46, 65],
        ["GP22", "GND4", 65, 65],
        ["GP21", "GND4", 84, 65],
        ["GP20", "GND4",  8, 84],
        ["GP19", "GND4", 27, 84],
        ["GP18", "GND4", 46, 84],
        ["GP17", "GND4", 65, 84],
        ["GP16", "GND4", 84, 84]
    ]

    const KEY_WIDTH = 19
    let i = 0
    let j = 0
    let type1_net_range = []
    let type2_net_range = []
    let type7_net_range = []
    let type8_net_range = []
    let item = undefined
    //const nets = []
    let ranges = undefined
    let min_x = undefined
    let max_x = undefined
    let min_y = undefined
    let max_y = undefined
    for (i = 0; i < type1_net.length; i++) {
        item = type1_net[i]
        min_x = item[2] - KEY_WIDTH / 2 // x
        max_x = item[2] + KEY_WIDTH / 2 // x
        min_y = item[3] - KEY_WIDTH / 2 // y
        max_y = item[3] + KEY_WIDTH / 2 // y
        item.push(min_x, max_x, min_y, max_y)
        type1_net_range.push(item)
    }
    for (i = 0; i < type2_net.length; i++) {
        item = type2_net[i]
        min_x = item[2] - KEY_WIDTH / 2 // x
        max_x = item[2] + KEY_WIDTH / 2 // x
        min_y = item[3] - KEY_WIDTH / 2 // y
        max_y = item[3] + KEY_WIDTH / 2 // y
        item.push(min_x, max_x, min_y, max_y)
        type2_net_range.push(item)
    }
    for (i = 0; i < type7_net.length; i++) {
        item = type7_net[i]
        min_x = item[2] - KEY_WIDTH / 2 // x
        max_x = item[2] + KEY_WIDTH / 2 // x
        min_y = item[3] - KEY_WIDTH / 2 // y
        max_y = item[3] + KEY_WIDTH / 2 // y
        item.push(min_x, max_x, min_y, max_y)
        type7_net_range.push(item)
    }
    for (i = 0; i < type8_net.length; i++) {
        item = type8_net[i]
        min_x = item[2] - KEY_WIDTH / 2 // x
        max_x = item[2] + KEY_WIDTH / 2 // x
        min_y = item[3] - KEY_WIDTH / 2 // y
        max_y = item[3] + KEY_WIDTH / 2 // y
        item.push(min_x, max_x, min_y, max_y)
        type8_net_range.push(item)
    }
    if (type === 1) {
        ranges = type1_net_range
    } else if (type === 2) {
        ranges = type2_net_range
    } else if (type === 7) {
        ranges = type7_net_range
    } else if (type === 8) {
        ranges = type8_net_range
    }
    console.log('range', ranges)
    for (i = 0; i < keys.length; i++) {
        item = keys[i]
        console.log('item', item)
        if (!item.empty) {
            for (j = 0; j < ranges.length; j++) {
                let range = ranges[j]
                if (range[4] <= item.x && item.x < range[5] && range[6] <= item.y && item.y < range[7]) {
                    item.net = ranges[j].slice(0, 2)
                    //nets.push(item)
                    break
                }
            }
        }

    }
    //return nets
}

export { getNetwork }

/*
        ┌──────┐
        │   2○─┼─
       ─┼─○1   │
        │      │
        └──────┘
*/
import { useEffect, useState, useRef } from 'react'
import React from 'react';
import Sourface from './Sourface'
import DownloadIcon from '@mui/icons-material/Download'
import { Button, 
  Box,
  CardContent,
  Typography,
  Stack,
  Paper } from '@mui/material'
import { getImageUrls, 
  getImageStatus,
  getHasImageStatus } from '../models/ImageManager'
import CircularProgress from '@mui/material/CircularProgress'


function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{props.label}</Typography>
      </Box>
    </Box>
  )
}


export default function Images(props) {
  
  const [projectId, ] = useState(props.projectId)
  const [imageUrls, setImageUrls] = useState({
    svg: {
      top: '', 
      bottom: ''
    },
    pdf: {
      top: '', 
      bottom: ''
    }
  })
  const [imageStatus, setImageStatus] = useState(null)
  const [imageFailed, setImageFailed] = useState(false)
  const [imageWatching, setImageWatching] = useState(false)
  const [imageGenerated, setImageGenerated] = useState(false)
  
  let imageTimer = useRef()

  function getStatusWithProgress() {
    const status = [
      "started",
      "completed"
    ]
    if (0 <= status.indexOf(imageStatus)) {
      return (status.indexOf(imageStatus) + 1) + '/' + status.length
    }
    return ""
  }
  
  useEffect(() => {
    getHasImageStatus(projectId).then(data => {
      console.log('getHasImageStatus', data)
      if (data === true) {
        setImageWatching(true)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId])
  
  
  useEffect(() => {
    if (imageWatching === true) {
      function intervalImageStatus() {
        getImageStatus(projectId).then(data => {
          console.log('getImageStatus', data)
          setImageStatus(data)
        })
      }
      imageTimer.current = setInterval(intervalImageStatus, 3000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageWatching, projectId])
  

  useEffect(() => {
    if (imageStatus === "completed" || imageStatus === "error") {
      setImageWatching(false)
      clearInterval(imageTimer.current)
      if (imageStatus === "completed") {
        setImageGenerated(true)
      } else {
        setImageFailed(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageStatus])
  
  
  useEffect(() => {
    console.log("imageGenerated " + imageGenerated)
    getImageUrls(projectId).then(data => {
      setImageUrls(data)
      props.setDone(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageGenerated, projectId, props])
  
 
  return (
    <Box>
      { imageGenerated ?
          <Paper
            sx={{
              bgcolor: '#334',
              mt: 2,
              mb: 2
            }}
            variant="outlined">
            { imageStatus && imageUrls.svg.top !== '' ?
                <CardContent>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}>
                    <Typography>Top</Typography>
                    <Button 
                      endIcon={<DownloadIcon />}
                      href={imageUrls.pdf.top}>
                      PDF
                    </Button>
                  </Stack>
                  <Sourface src={imageUrls.svg.top} />
                </CardContent>
                :
                ''
            }
            { imageStatus && imageUrls.svg.bottom !== '' ?
                <CardContent>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}>
                    <Typography>Bottom</Typography>
                    <Button 
                      endIcon={<DownloadIcon />}
                      href={imageUrls.pdf.bottom}>
                      PDF
                    </Button>
                  </Stack>
                  <Sourface src={imageUrls.svg.bottom} />
                </CardContent>
              :
                ''
            }
          </Paper>
        :
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center' }}>
            { !imageFailed ?
              <CircularProgressWithLabel label={getStatusWithProgress()} />
            :
              ""
            }
            { imageStatus && !imageFailed ?
              <Typography variant="caption" mt={0.4} mb={0.1} sx={{ color: 'text.secondary' }}>status: {imageStatus}</Typography>
            :
              ""
            }
            <Typography>Images</Typography>
            { imageFailed ?
              <Typography variant="caption" mt={0.4} mb={0.1} sx={{ color: 'text.secondary' }}>genetation failed</Typography>
            :
              ""
            }
          </Box>
      }
    </Box>
  )
}

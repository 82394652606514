import { useState, useEffect } from 'react'
import React from 'react';
import { 
  Box,
  Button,
  Typography } from '@mui/material'
import Pcb from './Pcb'
import ThreeD from './ThreeD'
import Firmware from './Firmware'
import Enclosure from './Enclosure'
import Kbsvg from './Kbsvg'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { getOptionsUrl } from '../models/PathManager'


function Project() {
  
  let { projectId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  
  const [generated, setGenerated] = useState(false)

  const [doneThreeD, setDoneThreeD] = useState(false)
  const [donePcb, setDonePcb] = useState(false)
  const [doneFirmware, setDoneFirmware] = useState(false)
  const [doneEnclosure, setDoneEnclosure] = useState(false)
  
  function nextHandler() {
    navigate(getOptionsUrl(location.pathname, projectId))
  }

  useEffect(() => {
    console.log("project", doneThreeD, donePcb, doneFirmware, doneEnclosure)
    if (doneThreeD && donePcb && doneFirmware && doneEnclosure) {
      setGenerated(true)
    }
  }, [doneThreeD, donePcb, doneFirmware, doneEnclosure])
  
  return (
    <Box>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        mb: 7 }}>
        <Kbsvg projectId={projectId} />
      </Box>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        mb: 7 }}>
        <ThreeD projectId={projectId} setDone={setDoneThreeD} />
      </Box>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        mb: 5 }}>
        <Pcb projectId={projectId} setDone={setDonePcb} />
      </Box>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        mb: 6 }}>
        <Firmware projectId={projectId} setDone={setDoneFirmware} />
      </Box>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        mb: 6 }}>
        <Enclosure projectId={projectId} setDone={setDoneEnclosure} />
      </Box>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        mb: 9 }}>
        <Button 
          endIcon={<NavigateNextIcon/>}
          onClick={nextHandler}
          variant="contained"
          color="success"
          disabled={!generated}>
          Order
        </Button>
      </Box>
      { !generated ?
          <Typography 
            component="h1" 
            variant="h6" 
            align="center" 
            color="textPrimary"
            sx={{
              marginTop: 2,
              marginBottom: 2,
            }}>
            Generating files. It might take about 90 sec.
          </Typography>
        :
          ""
      }
    </Box>
  )
}

export default Project

import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import { 
  Container, 
  Button, 
  Box,
  Stack, 
  Divider } from '@mui/material'
import { setSvg, 
  setHiddenSvg,
  setEditorSize, 
  initEditor, 
  add_key, 
  enter_deleting_keys, 
  stop_deleting_keys, 
  setOnSelectAction,
  modify_key,
  unselect_key,
  getSvg,
  getKbdata,
  warnOutOfRange
} from '../svg/KeyboardEditor'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { ModifyDrawer } from './drawers/ModifyDrawer'
import { loadProject, saveProject, removeStatus } from '../models/ProjectManager'
import { getProjectUrl } from '../models/PathManager'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'


function Editor() {
  
  const { projectId } = useParams()
  let navigate = useNavigate()
  const location = useLocation()
  
  const [modifyDrawerState, setModifyDrawerState] = useState(false)
  const [deletingState, setDeletingState] = useState(false)
  const [showingState, ] = useState(false)
  const [modifyData, setModifyData] = useState({})
  const svg_ref = useRef(null)
  const hidden_svg_ref = useRef(null)

  const mainMenuStates = {
    deleting: deletingState,
    showing: showingState
  }
  
  const mainMenuHandlers = {
    // save: handleSave,
    deleting: stopDeleting,
    delete_key: deleteKey,
    go_project: handleGoProject
  }

  useEffect(() => {
    console.log("modifyData changed ", modifyData)
  }, [modifyData])
  
  useEffect(() => {
    setSvg(svg_ref.current)
    setHiddenSvg(hidden_svg_ref.current)
  }, [])
  
  useEffect(() => {
    console.log(projectId)
    async function doGet() {
      const json_data = await loadProject(projectId)
      console.log(json_data)
      initEditor(json_data)
      setOnSelectAction(openModifyDrawer)
    }
    doGet()
  }, [projectId])
  
  useEffect(() => {
    function handleResize() {
      setEditorSize()
    }
    window.addEventListener("resize", handleResize)
    handleResize()
    return () => window.removeEventListener("resize", handleResize)
  }, [])
  
  function handleSave(event) {
    const data = getKbdata()
    saveProject(data, getSvg(data), projectId)
    removeStatus(projectId)
    console.log(data)
  }
  
  function toggleModifyDrawer() {
    setModifyDrawerState(!modifyDrawerState)
  }

  function openModifyDrawer(data) {
    console.log(data)
    setModifyData(data)
    setModifyDrawerState(true)
  }
  
  function closeModifyDrawer() {
    unselect_key()
    setModifyDrawerState(false)
  }
  
  function applyModify(data) {
    modify_key(data)
    closeModifyDrawer()
  }
  
  function deleteKey() {
    enter_deleting_keys()
    setDeletingState(true)
  }
  
  function stopDeleting() {
    setDeletingState(false)
    stop_deleting_keys()
  }
  
  function handleGoProject() {
    if (!warnOutOfRange()) {
      handleSave()
      // status.jsonファイルの削除のために1秒待ってみる（効果があるのか不明）
      new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 1000)
      }).then(() => {
        navigate(getProjectUrl(location.pathname, projectId))
      })
    }
  }
  
  return (
    <React.Fragment>
      <Container maxWidth="lg" sx={{ mt: 5 }}>
        <ModifyDrawer 
          state={modifyDrawerState} 
          applyHandler={applyModify}
          data={modifyData}
          toggleState={toggleModifyDrawer}
          closeHandler={closeModifyDrawer}
          />
        <Box
          sx={{
            width: '100%',
            height: 35,
            marginBottom: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <MainMenu 
            states={mainMenuStates} 
            handlers={mainMenuHandlers}
          />
        </Box>
      </Container>
      
      <div ref={svg_ref} />
      <Box sx={{ visibility: 'hidden' }}>
        <div ref={hidden_svg_ref} />
      </Box>

    </React.Fragment> 
  )
}

function MainMenu(props) {
  
  if (props.states.deleting) {
    return (
      <Button onClick={props.handlers.deleting}>
        STOP DELETING
      </Button>  
    )
  } else {
    return (
      <Stack
        direction="row"
        spacing={2}>
        <Button onClick={add_key}>
          Add
        </Button>
        <Divider orientation="vertical" flexItem />
        <Button onClick={props.handlers.delete_key}>
          Delete
        </Button>
        <Divider orientation="vertical" flexItem />
        <Button onClick={props.handlers.go_project} variant="outlined" endIcon={<NavigateNextIcon/>}>
          Proceed
        </Button>
      </Stack>
    )
  }
}

export default Editor



import { Container, 
  Button, 
  Box, 
  Stack,
  Typography,
  Divider } from '@mui/material'
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CheckIcon from '@mui/icons-material/Check';
// import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { createNewProject } from '../models/ProjectManager'
import { getEditorUrl, getIsOnsite } from '../models/PathManager'
import { useNavigate, useLocation } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import SVG from "@svgdotjs/svg.js/src/svg"

let svg_container = null
const RATE = 3
const KEY_SIZE = 16 * RATE
const KEY_SPACE = 3 * RATE
const KEY_RADIUS = 2 * RATE
const KEY_START_X = 1
const KEY_START_Y = 1
const PICO_WIDTH = 21 * RATE
const PICO_HEIGHT = 51 * RATE
const USB_WIDTH = 6 * RATE
const USB_HEIGHT = 5 * RATE
const COLOR = '#37474f'
const USB_COLOR = '#203035'

function Home() {
  const svg1_ref = useRef(null)
  const svg2_ref = useRef(null)
  const svg7_ref = useRef(null)
  const svg8_ref = useRef(null)

  let navigate = useNavigate()
  const location = useLocation()

  const [isOnsite, ] = useState(getIsOnsite(location.pathname))

  async function onCreateType1Click() {
    const projectId = await createNewProject(1)
    console.log(getEditorUrl(location.pathname, projectId))
    navigate(getEditorUrl(location.pathname, projectId))
  }

  async function onCreateType2Click() {
    const projectId = await createNewProject(2)
    navigate(getEditorUrl(location.pathname, projectId))
  }

  async function onCreateType7Click() {
    const projectId = await createNewProject(7)
    navigate(getEditorUrl(location.pathname, projectId))
  }

  async function onCreateType8Click() {
    const projectId = await createNewProject(8)
    navigate(getEditorUrl(location.pathname, projectId))
  }

  function drawPico(pico, nr) {
    pico.attr({ fill: COLOR })
    if (nr === true) {
      pico.rect(PICO_HEIGHT, PICO_WIDTH).radius(KEY_RADIUS - 2)
      const usb = pico.rect(USB_HEIGHT, USB_WIDTH).radius(1)
      usb.move(2, (PICO_WIDTH - USB_WIDTH) / 2).attr({ fill: USB_COLOR })
      const text = pico.text('Raspberry Pi Pico')
      text.move(23, 21).font({ 
        fill: USB_COLOR,
        family: 'Helvetica',
        weight: 'bold',
        size: 14 })
    } else {
      pico.rect(PICO_WIDTH, PICO_HEIGHT).radius(KEY_RADIUS - 2)
      const usb = pico.rect(USB_WIDTH, USB_HEIGHT).radius(1)
      usb.move((PICO_WIDTH - USB_WIDTH) / 2, 2).attr({ fill: USB_COLOR })
      const text = pico.text('Raspberry Pi Pico')
      text.rotate(90)
      text.move(93, 20).font({ 
        fill: USB_COLOR,
        family: 'Helvetica',
        weight: 'bold',
        size: 14 })
    } 
  }

  function setSvg1(ref) {
    svg_container = SVG().addTo(ref)
    svg_container.size(296, 230)
    var nst = svg_container.nested()
    var pico = nst.nested()
    drawPico(pico, false)
    pico.move(KEY_START_X + 1 + (KEY_SIZE + KEY_SPACE) * 4, KEY_START_Y)
    // キーを追加する
    for (var i = 0; i < 4; i++) {
      for (var j = 0; j < 4; j++) {
        var key = nst.rect(KEY_SIZE, KEY_SIZE).radius(KEY_RADIUS)
        key.attr({ fill: COLOR })
        key.move(
          KEY_START_X + i * (KEY_SIZE + KEY_SPACE), 
          KEY_START_Y + j * (KEY_SIZE + KEY_SPACE)
        )
      }
    }
    const text11 = nst.text('<·········· up to 4 ··········>')
    text11.move(3, 17).font({  // 3, 97
      fill: '#777',
      family: 'Helvetica',
      weight: 'bold',
      size: 20 })
    const text12 = nst.text('<·········· up to 4 ··········>') // •••
    text12.rotate(90).move(94, -123).font({ // 128, 15
      fill: '#777',
      family: 'Helvetica',
      weight: 'bold',
      size: 20 })
  }

  function setSvg2(ref) {
    svg_container = SVG().addTo(ref)
    svg_container.size(290, 236)
    var nst = svg_container.nested()
    var pico = nst.nested()
    drawPico(pico, true)
    pico.move(KEY_START_X, KEY_START_Y)
    // キーを追加する
    for (var i = 0; i < 5; i++) {
      for (var j = 0; j < 3; j++) {
        var key = nst.rect(KEY_SIZE, KEY_SIZE).radius(KEY_RADIUS)
        key.attr({ fill: COLOR })
        key.move(
          KEY_START_X + i * (KEY_SIZE + KEY_SPACE), 
          PICO_WIDTH + 10 + KEY_START_Y + j * (KEY_SIZE + KEY_SPACE)
        )
      }
    }
    const text21 = nst.text('<··············· up to 5 ···············>')
    text21.move(3, 91).font({  // 3, 97
      fill: '#777',
      family: 'Helvetica',
      weight: 'bold',
      size: 20 })
    const text22 = nst.text('<····· up to 3 ·····>') // •••
    text22.rotate(90).move(141, -204).font({ // 128, 15
      fill: '#777',
      family: 'Helvetica',
      weight: 'bold',
      size: 20 })
  }

  function setSvg7(ref) {
    svg_container = SVG().addTo(ref)
    svg_container.size(280, 350)
    var nst = svg_container.nested()
    var pico = nst.nested()
    drawPico(pico, true)
    pico.move(KEY_START_X, KEY_START_Y)
    // キーを追加する
    for (var i = 0; i < 5; i++) {
      for (var j = 0; j < 5; j++) {
        var key = nst.rect(KEY_SIZE, KEY_SIZE).radius(KEY_RADIUS)
        key.attr({ fill: COLOR })
        key.move(
          KEY_START_X + i * (KEY_SIZE + KEY_SPACE), 
          PICO_WIDTH + 10 + KEY_START_Y + j * (KEY_SIZE + KEY_SPACE)
        )
      }
    }
    const text71 = nst.text('<··············· up to 5 ···············>')
    text71.move(3, 90).font({  // 3, 97
      fill: '#777',
      family: 'Helvetica',
      weight: 'bold',
      size: 20 })
    const text72 = nst.text('<··············· up to 5 ···············>') // •••
    text72.rotate(90).move(190, -155).font({ // 128, 15
      fill: '#777',
      family: 'Helvetica',
      weight: 'bold',
      size: 20 })
  }

  function setSvg8(ref) {
    svg_container = SVG().addTo(ref)
    svg_container.size(350, 280)
    var nst = svg_container.nested()
    var pico = nst.nested()
    drawPico(pico, false)
    pico.move(KEY_START_X + 1 + (KEY_SIZE + KEY_SPACE) * 5, KEY_START_Y)
    // キーを追加する
    for (var i = 0; i < 5; i++) {
      for (var j = 0; j < 5; j++) {
        var key = nst.rect(KEY_SIZE, KEY_SIZE).radius(KEY_RADIUS)
        key.attr({ fill: COLOR })
        key.move(
          KEY_START_X + i * (KEY_SIZE + KEY_SPACE), 
          KEY_START_Y + j * (KEY_SIZE + KEY_SPACE)
        )
      }
    }
    const text81 = nst.text('<··············· up to 5 ···············>')
    text81.move(3, 16).font({  // 3, 97
      fill: '#777',
      family: 'Helvetica',
      weight: 'bold',
      size: 20 })
    const text82 = nst.text('<··············· up to 5 ···············>') // •••
    text82.rotate(90).move(115, -155).font({ // 128, 15
      fill: '#777',
      family: 'Helvetica',
      weight: 'bold',
      size: 20 })
  }

  useEffect(() => {
    if (isOnsite) {
      setSvg1(svg1_ref.current)
      setSvg2(svg2_ref.current)
    } else {
      setSvg7(svg7_ref.current)
      setSvg8(svg8_ref.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
      <Container>
        
        <Box 
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            height: '80vh'
          }}>
          <Stack
            direction="column"
            spacing={6}>
            <Typography 
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                height: '1vh'
              }}>
              Select one of the models.
            </Typography>
          <Stack
            direction="row"
            spacing={4}>
            { isOnsite ?
              <>
                <Stack spacing={2}>
                  <Button
                    onClick={onCreateType1Click}
                    variant="outlined"
                    endIcon={<CheckIcon />}>
                    Model 1
                  </Button>
                  <div ref={svg1_ref}/>
                </Stack>
                <Divider orientation="vertical" flexItem />
                <Stack spacing={2}>
                  <Button
                    onClick={onCreateType2Click}
                    variant="outlined"
                    endIcon={<CheckIcon />}>
                    Model 2
                  </Button>
                  <div ref={svg2_ref}/>
                </Stack>
              </>
            :
              <>
                <Stack spacing={2}>
                  <Button
                    onClick={onCreateType7Click}
                    variant="outlined"
                    endIcon={<CheckIcon />}>
                    Model 7
                  </Button>
                  <div ref={svg7_ref}/>
                </Stack>
                <Divider orientation="vertical" flexItem />
                <Stack spacing={2}>
                  <Button
                    onClick={onCreateType8Click}
                    variant="outlined"
                    endIcon={<CheckIcon />}>
                    Model 8
                  </Button>
                  <div ref={svg8_ref}/>
                </Stack>
              </>
            }
          </Stack>
          </Stack>
        </Box>
      </Container>
  )
}

export default Home



import { Button, 
  Box, 
  Stack, 
  Drawer,
  Select, 
  MenuItem, 
  FormControl,
  InputLabel,
  ListSubheader } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useState, useEffect } from 'react'
import { 
  WIDTH_UNIT_OPTIONS, 
  KEY_OPTIONS, 
  KEY_OPTIONS_DICT } from '../../svg/Kbdata'


function ModifyDrawer(props) {
  
  const [data, setData] = useState({
    top: '',
    bottom: '',
    code: '',
    width: 1,
    empty: false
  })

  const [changed, setChanged] = useState(false)

  useEffect(() => {
    if (Object.keys(props.data).length !== 0) {
      setData(props.data)
    }
    setChanged(false)
  }, [props.data])
  
  /*
  function marginChanged(event) {
    const _modifyData = {...modifyData}
    _modifyData.margin = event.target.value
    setModifyData(_modifyData)
  }
  */
  
  function widthChanged(event) {
    const _modifyData = {...data}
    _modifyData.width = event.target.value
    setData(_modifyData)
    setChanged(true)
  }
  
  function keyChanged(event) {
    const _modifyData = {...data}
    _modifyData.code = event.target.value
    _modifyData.top = KEY_OPTIONS_DICT[event.target.value].top
    _modifyData.bottom = KEY_OPTIONS_DICT[event.target.value].bottom
    setData(_modifyData)
    setChanged(true)
  }

  function emptyChanged(event) {
    const _modifyData = {...data}
    _modifyData.empty = event.target.value
    setData(_modifyData)
    setChanged(true)
  }
  
  return (
    <Drawer
      anchor='top'
      open={props.state}
      elevation={1}
      hideBackdrop={true}
    >
      <Box
        sx={{
          width: '100%',
          height: 100,
          marginTop: 2,
          marginBottom: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Stack direction="row" spacing={2}>
          <FormControl variant="standard" sx={{ minWidth: 125 }}>
            <InputLabel id="select-code">key</InputLabel>
            <Select
              labelId="select-code"
              id="code"
              value={data.code}
              onChange={keyChanged}
              label="Code">
              {KEY_OPTIONS.map((row, index) => (
                row.type !== 'head' ?
                  (data.empty ?
                    (<MenuItem key={row.value} value={row.value} disabled>{row.text}</MenuItem>)
                    :
                    (<MenuItem key={row.value} value={row.value}>{row.text}</MenuItem>)
                  ) 
                :
                  (<ListSubheader key={row.text}>{row.text}</ListSubheader>)
              ))}
            </Select>
          </FormControl>
          { !props.keycodeOnly ?
              <FormControl variant="standard" sx={{ minWidth: 125 }}>
                <InputLabel id="select-width">width</InputLabel>
                <Select
                  labelId="select-width"
                  id="width"
                  value={data.width}
                  onChange={widthChanged}
                  label="Width">
                  {WIDTH_UNIT_OPTIONS.map((row, index) => (
                    (data.empty || 1 <= row.value) ?
                      <MenuItem key={row.value} value={row.value}>{row.text}</MenuItem>
                    :
                      <MenuItem key={row.value} value={row.value} disabled>{row.text}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            :
              ""
          }
          { !props.keycodeOnly ?
              <FormControl variant="standard" sx={{ minWidth: 125 }}>
                <InputLabel id="select-empty">empty</InputLabel>
                <Select
                  labelId="select-empty"
                  id="empty"
                  value={data.empty}
                  onChange={emptyChanged}
                  label="Empty">
                  <MenuItem key={false} value={false}>false</MenuItem>
                  <MenuItem key={true} value={true}>true</MenuItem>
                </Select>
              </FormControl>
            :
              ""
          }
          <Button onClick={() => props.applyHandler(data)} disabled={!changed}>apply</Button>
          <Button onClick={props.closeHandler}><CloseIcon /></Button>
        </Stack>
      </Box>
    </Drawer>
  )
}

export { ModifyDrawer }


import * as React from 'react'
import { useState, useEffect } from 'react'
import { 
  Container, 
  Button, 
  Box,
  TextField
 } from '@mui/material'
import { createCustomProject } from '../models/ProjectManager'
import { useNavigate } from 'react-router-dom'


function Upload() {
  
  let navigate = useNavigate()
  
  const [kbdatastr, setKbdataStr] = useState("")
  const [kbdata, setKbdata] = useState(null)
  const [disabled, setDisabled] = useState(true)

  useEffect(() => {
    setDisabled(true)
    if (kbdatastr !== "") {
      var parsed = {}
      try {
        parsed = JSON.parse(kbdatastr)
      } catch (error) {
        //
      } finally {
        if (parsed.keys && parsed.board && parsed.type) {
          parsed.upload = true
          setDisabled(false)
          setKbdata(parsed)
        }
      }
    }
  }, [kbdatastr])

  async function handleUpload() {
    if (kbdata) {
      var projectId = await createCustomProject(kbdata)
      navigate('/keymap/edit/' + projectId)
    }
  }
  
  return (
    <Container maxWidth="md" sx={{ mt: 3 }}>
      <TextField
        label="kbdata.json"
        multiline
        maxRows={15}
        minRows={15}
        fullWidth={true}
        onChange={(e)=>{
          setKbdataStr(e.target.value)
        }}
      />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
      }}>
        <Button onClick={handleUpload} variant="outlined" disabled={disabled}
          sx={{ m: 2 }}>
          Upload
        </Button>
      </Box>
    </Container>
  )
}


export default Upload



import { useState, useEffect, useRef } from 'react'
import React from 'react';
import { 
  Box,
  Stack } from '@mui/material'
import { ReactSVG } from 'react-svg'
import { getKeyboardSvgUrl } from '../models/ProjectManager'
import CircularProgress from '@mui/material/CircularProgress'


export default function Kbsvg(props) {

  let kbSvgTimer = useRef()

  const [projectId, ] = useState(props.projectId)
  const [keyboardSvgUrl, setKeyboardSvgUrl] = useState(null)

  function intervalKbSvgStatus() {
    console.log('intervalKbSvgStatus')
    getKeyboardSvgUrl(projectId).then(data => {
      setKeyboardSvgUrl(data)
      clearInterval(kbSvgTimer.current)
    })
  }

  useEffect(() => {
    kbSvgTimer.current = setInterval(intervalKbSvgStatus, 3000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId])


  return (
    <Box>
      { keyboardSvgUrl ?
          <Stack spacing={0}>
            <ReactSVG src={keyboardSvgUrl} />
          </Stack>
        :
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center' }}>
            <CircularProgress />
          </Box>
      }
    </Box>
  )
}


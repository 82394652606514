/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const goCheckoutV2 = /* GraphQL */ `
  mutation GoCheckoutV2($dir: String) {
    goCheckoutV2(dir: $dir) {
      created
      root_dir
      project_dir
      price
      number
      currency
      __typename
    }
  }
`;

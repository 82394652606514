import { constants } from './Constants'
import { graphqlOperation, API, Storage } from 'aws-amplify'
import { getGcodeV2 } from '../graphql/queries'


async function getHasGcodeStatus(projectId) {
  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  args.cacheControl = 'no-cache'
  args.validateObjectExistence = true
  try {
    await Storage.get(
      projectId + "/" + constants.GCODE_DIR + "/" + constants.STATUS_JSON, 
      args)
    return true
  } catch (err) {
    return false
  }
}


async function loadGcodeInfo(projectId) {
  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  args.cacheControl = 'no-cache'
  args.download = true
  // args.validateObjectExistence = true
  try {
    const data = await Storage.get(
      projectId + "/" + constants.GCODE_DIR + "/" + constants.STATUS_JSON, 
      args)
      .then(response => {
        return response.Body.text()
      })
    return JSON.parse(data)
  } catch (err) {
    console.log('error loadGcodeInfo', err)
    return JSON.parse("{}")
  }
}


async function getGcodeStatus(projectId) {
  try {
    const data = await loadGcodeInfo(projectId)
    /*
      {
        "created": "2021-10-21 21:55:04.233450",
        "completed": "2021-10-21 21:55:05.979837",
      }
    */
    const sorted = []
    for (var key in data) {
      sorted.push([key, data[key]])
    }
    sorted.sort(function(a, b) {
        return b[1] - a[1]
    })
    return sorted[0][0]
  } catch (err) {
    console.log('error getGcodeStatus', err)
  }
}


async function checkGcodeUpdated(projectId) {
  let pcb_last_modified = new Date(2000, 1, 2)
  let gcode_last_modified = new Date(2000, 1, 1)
  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  try {
    const result = await Storage.getProperties(
      projectId + '/' + constants.BOARD_DIR + '/' + constants.STATUS_JSON, 
      args)
    pcb_last_modified = result.lastModified
  } catch (err) {
    console.log('error pcb_last_modified') 
  }

  try {
    const result = await Storage.getProperties(
      projectId + '/' + constants.GCODE_DIR + '/' + constants.STATUS_JSON, 
      args)
    gcode_last_modified = result.lastModified
  } catch (err) {
    console.log('error gcode_last_modified') 
  }
  console.log('pcb_last_modified ', pcb_last_modified)
  console.log('gcode_last_modified ', pcb_last_modified)
  return pcb_last_modified < gcode_last_modified
}


async function generateGcode(projectId) {
  console.log('generateGcode') 
  try {
    await API.graphql(
      graphqlOperation(
        getGcodeV2, {
          dir: projectId
        }
      )
    )
  } catch (err) {
    console.log('error generateGcode') 
  }
}


async function getGcodeFile(projectId) {
  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  args.cacheControl = 'no-cache'
  args.download = true
  try {
    const data = await Storage.get(
      projectId + "/" + constants.GCODE_DIR + "/" + constants.GCODE_FILE,
      args
    )
    return data
  } catch (err) {
    console.log('error getBoard', err)
  }
}


export { getGcodeStatus,
  getGcodeFile,
  getHasGcodeStatus,
  generateGcode,
  checkGcodeUpdated
}

import { constants } from './Constants'
import { graphqlOperation, API, Storage } from 'aws-amplify'
import { getOptionsV2 } from '../graphql/queries'


async function getOptions(isOnsite) {
  console.log('getOptions') 
  try {
    const value = await API.graphql(
      graphqlOperation(
        getOptionsV2, {
          is_onsite: isOnsite
        }
      )
    )
    return value.data.getOptionsV2
  } catch (err) {
    console.log('error getOptions') 
  }
}

async function saveOptions(projectId, data) {
  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  
  try {
    await Storage.put(projectId + '/' + constants.OPTIONS_JSON, 
      JSON.stringify(data, null , "\t"), 
      args
    )
  } catch (err) {
    console.log('error putContent ' + constants.ORDER_JSON, err)
  }

}


export { 
  saveOptions,
  getOptions
}
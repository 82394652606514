import { 
  Toolbar,
  Typography,
  Breadcrumbs,
  Link,
  Button,
  IconButton,
  DialogContentText,
  Box,
  Divider
} from '@mui/material'
import * as React from 'react'
import { useState, useEffect } from 'react'
import { Outlet, useMatches, useLocation, useParams, useNavigate } from "react-router-dom"
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { blueGrey } from '@mui/material/colors'
import CssBaseline from '@mui/material/CssBaseline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { QRCodeSVG } from 'qrcode.react'
import { getProjectExists, getHasOrdered } from '../models/ProjectManager'
import { getHomeUrl, getPagename, getUrl } from '../models/PathManager'


const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: blueGrey[300]
    },
    background: {
      default: blueGrey[900]
    }
  }
})

function Crumbs(props) {
  console.log("Crumbs matches", props.matches)
  const items = [
    ["HOME", ""], 
    ["EDITOR", "editor"], 
    ["PROJECT", "project"], 
    ["OPTIONS", "options"], 
    ["ORDER", null]
  ]
  let path = props.matches[1].pathname
  let projectId = null
  if (props.matches[1].params && props.matches[1].params.projectId) {
    projectId = props.matches[1].params.projectId
  }
  path = getPagename(path, projectId)
  console.log("getPagename " + path)
  const breadcrumbs = []
  for (let item of items) {
    let clone = Object.assign({}, item)
    if (item[0] === "HOME") {
      clone[1] = getHomeUrl(props.matches[1].pathname)
    } else {
      clone[1] = getUrl(clone[1], props.matches[1].pathname, projectId)
    }
    breadcrumbs.push(clone)
    if (item[1] === path) {
      breadcrumbs[breadcrumbs.length - 1][1] = null
      break
    }
  }
  return (
    <Breadcrumbs 
      sx={{
        mt: 3,
        ml: 2
      }}
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb">
      {breadcrumbs.map( breadcrumb => (
        (
          breadcrumb[1] ?
            <Link key={breadcrumb[0]} href={breadcrumb[1]}>{breadcrumb[0]}</Link>
            :
            <Typography key={breadcrumb[0]}>{breadcrumb[0]}</Typography>
        )
      ))}
    </Breadcrumbs>
  )
}

function Root(props) {

  const matches = useMatches()
  const location = useLocation()
  const { projectId } = useParams()
  const navigate = useNavigate()

  const FORMURL_SHORT = "https://forms.gle/jSkGDkUa1ocv4RrWA"
  const FORMURL_PREFIX = "https://docs.google.com/forms/d/e/1FAIpQLSco0HFr5DU9uK_ciUfZ73vb5H-ECmpkvs-OJaVwr1OKIcDd2w/viewform?usp=pp_url&entry.1960556003="

  const [open, setOpen] = useState(false)
  const [thisurl, setThisUrl] = useState("")
  const [formurl, setFormUrl] = useState("")
  const [hasOrdered, setHasOrdered] = useState(null)
  const [projectExists, setProjectExists] = useState(null)
  
  function openContact() {
    console.log("openContact")
    setOpen(true)
  }

  function closeContact() {
    console.log("closeContact")
    setOpen(false)
  }

  console.log("projectId " + projectId)

  useEffect(() => {
    console.log(
      "projectId:" + projectId +
      ", projectExists:" + projectExists +
      ", hasOrdered:" + hasOrdered +
      ", location.pathname:" + location.pathname
    )
  }, [projectId, projectExists, hasOrdered, location.pathname])

  useEffect(() => {
    async function doGetExists() {
      const exists = await getProjectExists(projectId)
      setProjectExists(exists)
    }
    if (projectId !== undefined) {
      doGetExists()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId])

  useEffect(() => {
    if (projectExists === false) {
      navigate(getHomeUrl(location.pathname))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectExists])

  useEffect(() => {
    async function doGetHas() {
      const _ho = await getHasOrdered(projectId)
      setHasOrdered(_ho)
      if (_ho) {
        navigate('/order/' + projectId)
      }
    }
    if (projectId !== undefined && projectExists === true) {
      doGetHas()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, projectExists])

  useEffect(() => {
    setThisUrl(window.location.protocol + "//" + window.location.host + location.pathname)
  }, [location.pathname])

  useEffect(() => {
    if (location.pathname === "/") {
      setFormUrl(FORMURL_SHORT)
    } else {
      setFormUrl(FORMURL_PREFIX + thisurl)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thisurl])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toolbar>
        <Typography 
          variant="h6" 
          component="div" 
          sx={{ flexGrow: 1 }}>
          <Crumbs matches={matches} />
        </Typography>
        <IconButton 
          onClick={openContact}>
          <ErrorOutlineIcon />
        </IconButton>
      </Toolbar>
      <Dialog open={open}
        fullWidth={true}
        maxWidth="xs"
        >
        <DialogContent sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          m: 3 }}>
          <DialogContentText sx={{ mb: 2 }}>
            Contact Form
          </DialogContentText>
          <QRCodeSVG value={formurl} />
        </DialogContent>
        <Divider variant="middle" />
        <DialogContent sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          m: 3 }}>
          <DialogContentText sx={{ mb: 1 }}>
            URL
          </DialogContentText>
          <Box sx={{
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            mb: 2 }}>
            <Typography variant="caption" component="div">
              {thisurl}
            </Typography>
          </Box>
          <QRCodeSVG value={thisurl} />
        </DialogContent>
        <DialogActions sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          m: 2
          }}>
          <Button onClick={closeContact}>Close</Button>
        </DialogActions>
      </Dialog>
      {(projectExists === true && hasOrdered === false) || location.pathname === getHomeUrl(location.pathname)
        ? <Outlet />
        : ""
      }
    </ThemeProvider>
  )
}

export default Root

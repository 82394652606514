/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getKicadPcb = /* GraphQL */ `
  query GetKicadPcb($project_dir: String) {
    getKicadPcb(project_dir: $project_dir)
  }
`;
export const getFirmware = /* GraphQL */ `
  query GetFirmware($project_dir: String) {
    getFirmware(project_dir: $project_dir)
  }
`;
export const getGcode = /* GraphQL */ `
  query GetGcode($project_dir: String, $event_dir: String) {
    getGcode(project_dir: $project_dir, event_dir: $event_dir)
  }
`;
export const getStl = /* GraphQL */ `
  query GetStl($project_dir: String) {
    getStl(project_dir: $project_dir)
  }
`;
export const getKicadPcbV2 = /* GraphQL */ `
  query GetKicadPcbV2($dir: String) {
    getKicadPcbV2(dir: $dir)
  }
`;
export const getFirmwareV2 = /* GraphQL */ `
  query GetFirmwareV2($dir: String) {
    getFirmwareV2(dir: $dir)
  }
`;
export const getGcodeV2 = /* GraphQL */ `
  query GetGcodeV2($dir: String) {
    getGcodeV2(dir: $dir)
  }
`;
export const getStlV2 = /* GraphQL */ `
  query GetStlV2($dir: String) {
    getStlV2(dir: $dir)
  }
`;
export const getStlEnclosureV2 = /* GraphQL */ `
  query GetStlEnclosureV2($dir: String) {
    getStlEnclosureV2(dir: $dir)
  }
`;
export const getPriceV2 = /* GraphQL */ `
  query GetPriceV2($dir: String) {
    getPriceV2(dir: $dir) {
      price
      currency
      __typename
    }
  }
`;
export const getPresignedUrlV2 = /* GraphQL */ `
  query GetPresignedUrlV2($s3_key: String) {
    getPresignedUrlV2(s3_key: $s3_key)
  }
`;
export const getOptionsV2 = /* GraphQL */ `
  query GetOptionsV2($is_onsite: Boolean) {
    getOptionsV2(is_onsite: $is_onsite) {
      keyswitchs {
        id
        sort
        title
        desc
        src
        price
        currency
        disable
        __typename
      }
      keycaps {
        id
        sort
        title
        desc
        src
        price
        currency
        disable
        __typename
      }
      enclosures {
        id
        sort
        title
        desc
        src
        price
        currency
        disable
        __typename
      }
      boards {
        id
        sort
        title
        desc
        src
        price
        currency
        disable
        __typename
      }
      modules {
        id
        sort
        title
        desc
        src
        price
        currency
        disable
        __typename
      }
      assemblies {
        id
        sort
        title
        desc
        src
        price
        currency
        disable
        __typename
      }
      shippings {
        id
        sort
        title
        desc
        src
        price
        currency
        disable
        __typename
      }
      __typename
    }
  }
`;
export const getCheckoutUrlV2 = /* GraphQL */ `
  query GetCheckoutUrlV2($dir: String) {
    getCheckoutUrlV2(dir: $dir)
  }
`;
export const getDownloadUrlV2 = /* GraphQL */ `
  query GetDownloadUrlV2($dir: String) {
    getDownloadUrlV2(dir: $dir)
  }
`;
export const getOrderListV2 = /* GraphQL */ `
  query GetOrderListV2 {
    getOrderListV2 {
      id
      short_id
      created_at
      created_ts
      keynum
      width
      height
      options
      shipping
      total
      __typename
    }
  }
`;
